var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Integration" }, [
    _c("div", { staticClass: "integration-title" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.integrationTitle)),
      ]),
      _c("div", { staticClass: "divider" }),
    ]),
    _c(
      "div",
      { staticClass: "Box-container" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "items" },
          [
            _c("div", { staticClass: "item-title" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(_vm.$t("Integration." + _vm.setName(item.description)))
                ),
              ]),
              _c(
                "div",
                { staticClass: "box-header-right" },
                [
                  item.isActive || item.isUsed
                    ? _c("div", { staticClass: "label m-right-15" }, [
                        _vm._v(_vm._s(_vm.$t("Integration.installed"))),
                      ])
                    : _vm._e(),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: () => _vm.closePopup(item),
                          expression: "() => closePopup(item)",
                        },
                      ],
                      staticClass: "m-bottom-10 action-button",
                      attrs: { clearVariant: true },
                      on: {
                        click: function ($event) {
                          return _vm.openPopup(item)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v("..."),
                        _c(
                          "div",
                          {
                            staticClass: "action-popup",
                            class: item.showActionBar
                              ? "openActions"
                              : "closeActions",
                          },
                          [
                            _c(
                              "Button",
                              {
                                attrs: {
                                  variant: "Boxlist-action Boxlist-action-edit",
                                  id: `btn-integration-overview-${item.name}`,
                                  iconName: "icon-login-eye",
                                  iconClass: "icon-login-eye",
                                  clearVariant: true,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.activateOverview(item)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "m-left-10" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("Integration.overview"))
                                  ),
                                ]),
                              ]
                            ),
                            (item.to &&
                              item.isActive &&
                              item.name !== "TCMB") ||
                            item.name === "Sales Forecasting"
                              ? _c(
                                  "Button",
                                  {
                                    attrs: {
                                      variant:
                                        "Boxlist-action Boxlist-action-edit",
                                      id: `btn-integration-settings-${item.name}`,
                                      iconName: "icon-global-settings",
                                      iconClass: "icon-global-settings",
                                      clearVariant: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToPage(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "m-left-10" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("Integration.configuration")
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            item.isUsed ||
                            ((item.name === "Massec" ||
                              item.name === "iBar" ||
                              item.name === "TCMB" ||
                              item.name === "EMKO") &&
                              item.isActive)
                              ? _c(
                                  "Button",
                                  {
                                    attrs: {
                                      variant:
                                        "Boxlist-action Boxlist-action-edit",
                                      id: `btn-integration-uninstall-${item.name}`,
                                      iconName: "icon-delete",
                                      iconClass: "icon-delete",
                                      clearVariant: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.activateProduct(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "m-left-10" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Integration.uninstall"))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            !item.isActive && !item.isUsed
                              ? _c(
                                  "Button",
                                  {
                                    attrs: {
                                      variant:
                                        "Boxlist-action Boxlist-action-edit",
                                      id: `btn-integration-uninstall-${item.name}`,
                                      iconName: "icon-global-setup",
                                      iconClass: "icon-global-setup",
                                      clearVariant: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.activateProduct(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "m-left-10" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Integration.install"))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "box-content" }, [
              _c("img", {
                staticClass: "item_img",
                attrs: { src: `/static/images/integrations_${item.img}` },
              }),
              _c("span", { staticClass: "m-left-10 m-right-10" }, [
                _vm._v(
                  _vm._s(
                    _vm.getShortDesc(
                      _vm.$t(
                        "Integration.description_" +
                          _vm.setName(item.description)
                      )
                    )
                  )
                ),
              ]),
            ]),
            _c(
              "Modal",
              {
                attrs: { isOpen: item.isModalActive },
                on: {
                  closeModal: function ($event) {
                    return _vm.closeOverview(item)
                  },
                },
              },
              [
                _c("template", { slot: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Integration." + _vm.setName(item.description))
                    )
                  ),
                ]),
                _c("template", { slot: "content" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Integration.description_" +
                              _vm.setName(item.description)
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }