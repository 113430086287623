<template lang="pug">
Page(titleType="custom")
  template(slot="content-center")
    .IntegrationMassecPageContent
      .MassecImage
        img(src="/static/images/massec_calculate.png")

      .MassecContent
        .MassecContentLogo
          img(src="/static/images/massec_page_logo.png")
          p {{ $t('Integration.massecContentFirst') }}

</template>

<script>

export default {
  name: 'IntegrationMassec'
}
</script>
<style lang="scss" scoped>

  .IntegrationMassecPageContent {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 20px;
    margin-top: 77px;
  }

  .MassecImage {
    img {
      max-width: 80%;
    }
  }

  .MassecContent {
    align-self: flex-end;
    img {
      max-width: 190px;
    }
    p {
      margin-top: 39px;
    }
    .massecActionButton {
      max-width: max-content;
      margin-top: 20px;
    }
  }
</style>
