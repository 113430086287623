import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'

export default {
  namespaced: true,

  state: {
    pageTabLinks: [
      'WarehouseManagementAxata',
      'WarehouseManagementAxataSupplier',
      'WarehouseManagementAxataStore',
    ],
    selectedStoreGroup: {},
    intWmsStoreGroupList: [],
  },

  getters: {
    pageTabLinks: state => state.pageTabLinks,
    selectedStoreGroup: state => state.selectedStoreGroup,
    IntWmsStoreGroupList: state => state.intWmsStoreGroupList,
  },

  actions: {

    async fetchWMSConfigStoreGroup({ commit }, payload) {
      const loadingName = 'fetchWMSConfigStoreGroup'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/wms/config/storegroup', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchWMSConfigStoreGroupAll({ commit }, payload) {
      const loadingName = 'fetchWMSConfigStoreGroup'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/wms/config/storegroup/all', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_INT_STORE_GROUP_LIST', result.data.intWmsStoreGroupList)
          return result
        }
      })
      return results
    },

    async updateWMSConfigStoreGroup({ commit }, payload) {
      let results = await requestHandler({
        title: 'updateWMSConfigStoreGroup',
        methodName: 'updateWMSConfigStoreGroup',
        method: () => {
          return HTTP.post('integration/wms/config/storegroup/save', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchWMSConfigSupplier({ commit }, payload) {
      const loadingName = 'fetchWMSConfigSupplier'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/wms/config/supplier/all', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async updateWMSConfigSupplier({ commit }, payload) {
      let results = await requestHandler({
        title: 'updateWMSConfigSupplier',
        methodName: 'updateWMSConfigSupplier',
        method: () => {
          return HTTP.post('integration/wms/config/supplier/save', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchWMSConfigStore({ commit }, payload) {
      const loadingName = 'fetchWMSConfigStore'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/wms/config/store/all', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async updateWMSConfigStore({ commit }, payload) {
      let results = await requestHandler({
        title: 'updateWMSConfigStore',
        methodName: 'updateWMSConfigStore',
        method: () => {
          return HTTP.post('integration/wms/config/store/save', payload)
        },
        success: result => result
      })
      return results
    },

  },

  /**
  */

  mutations: {
    SET_INT_STORE_GROUP_LIST(state, data) {
      state.intWmsStoreGroupList = data
    },

    SET_SELECTED_STORE_GROUP(state, storeGroup) {
      state.selectedStoreGroup = {
        ...storeGroup
      }
    },

    RESET_STORE_GROUP(state) {
      state.selectedStoreGroup = {}
    }
  }
}
