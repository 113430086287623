<template lang="pug">
DeleteAlert(
  :item="info",
  :isGetDataLoading="isDataLoading",
  :fields="fields",
  :tableName="$t('B2B.b2bRemoveDefinitionTitle')",
  loading="itemRemoveB2BConfig",
  @delegateOnAlertRemoveOk="itemRemove")

</template>

<script>

import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'IntegrationB2bConfigRemoveConfirm',

  data () {
    return {
      info: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('B2B.IntegrationName'),
        title: this.$t('B2B.IntegrationName')
      }]
    }
  },

  computed: {

    itemId () {
      return this.$route.params.id || null
    },

    integrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isDataLoading () {
      return this.$wait.is('fetchingB2bDefinitionInfo')
    }
  },

  async mounted () {
    const res = await this.fetchingB2bDefinitionInfo(this.$route.params.id)
    this.info = await res.data.intB2BDefinition
  },
  methods: {
    ...mapActions('IntegrationB2b', [
      'fetchingB2bDefinitionList',
      'fetchingB2bDefinitionInfo',
      'deleteB2bDefinition'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteB2bDefinition(this.itemId)
      if (res) {
        const message = this.$t('Global.successRemoveMessage', { field: this.info.name })
        this.notifyShow({ message })
        this.fetchingB2bDefinitionList({
          integrationProductId: this.integrationProductId
        })
        this.closePopup()
      }
    }, 'itemRemoveB2BConfig')
  }
}
</script>
