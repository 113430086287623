var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            staticClass: "m-left-10",
            attrs: {
              links: _vm.PageHeaderXeroLinks,
              disabledValues: _vm.filteredSettingsMenu,
              translationGroup: "Accounting",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.IntStoreGroupList,
              selectedItem: _vm.selectedStoreGroup,
              selectedKey: "storeGroupId",
              fieldKey: "storeGroupName",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-right" },
        [
          !_vm.IntegrationStoreGroupList.id
            ? _c(
                "Button",
                {
                  attrs: {
                    id: "connect-to-xero",
                    primary: "",
                    size: "small",
                    inline: "",
                  },
                  on: { click: _vm.connectToXero },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Integration.connectToXero"))),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.IntegrationStoreGroupList.id
            ? _c(
                "Button",
                {
                  attrs: {
                    id: "disconnect-from-xero",
                    primary: "",
                    size: "small",
                    inline: "",
                  },
                  on: { click: _vm.disconnetFromXero },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Integration.disconnectFromXero"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.IntegrationStoreGroupList.username
            ? _c("TableView", {
                attrs: {
                  data: [_vm.IntegrationStoreGroupList],
                  readOnly: true,
                  fields: _vm.intStoreGroupField,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }