<template lang="pug">
.gelir-aktarimi

    Button(
    size="small"
    variant="border-gray c-primary"
    :disabled="isLoadingImportMenuItems"
    id="btn-gelir-aktarimi",
    type="button",
    @click="onClickButton")
      Loading(v-if="isLoadingImportMenuItems", theme="disable")
      Icon(name="icon-postan-aktar")
      | {{ $t('MenuItems.salesUploadFile') }}

    input(
    ref="fileInput"
    type="file"
    @change="processFile($event)"
    :disabled="isLoading")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GelirAktarimi',
  computed: {
    isLoading () {
      return this.$wait.is(['postan-gelir-aktar'])
    },
    isLoadingImportMenuItems () {
      return this.$wait.is(['postan_gelir_aktar', 'processingFile'])
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),
    ...mapActions('MenuItems', [
      'postan_gelir_aktar'
    ]),

    onClickButton () {
      this.$refs.fileInput.click()
    },

    async processFile (event) {
      //
      this.$wait.start('processingFile')
      let formData = new FormData()
      formData.append('File', event.target.files[0])
      let message = this.$t('MenuItems.notification_TransferFail')
      //
      await this.postan_gelir_aktar(formData)
        .then(data => {
          if (data) {
            message = this.$t('MenuItems.notification_TransferSuccess')
            this.notifyShow({ message })
          }
        }).catch(err => {
          message = err.response.data.message
        }).then(() => {
          // this.notifyShow({ message })
          event.target.value = ''
          this.$wait.end('processingFile')
        })
    }
  }
}
</script>

<style lang="scss">

  .gelir-aktarimi {
    input {
     position: absolute;
     opacity: 0;
     pointer-events: none;
   }

   .btn {
     height: 55px;
     width: auto;
     margin-right: 5px;

     .icon {
       width: 24px;
       height: 24px;
     }
   }
  }
  .btn-no-border-blue {
    font-size: 16px;
  }
</style>
