var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            staticClass: "m-left-10",
            attrs: {
              links: _vm.PageHeaderLinks,
              disabledValues: _vm.filteredSettingsMenu,
              translationGroup: "Accounting",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            staticClass: "m-bottom-15",
            attrs: {
              list: _vm.IntStoreGroupList,
              selectedItem: _vm.selectedStoreGroup,
              selectedKey: "storeGroupId",
              fieldKey: "storeGroupName",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingSettingInfo
            ? _c("TableLoading")
            : _c("SettingsForm", {
                attrs: {
                  form: _vm.form,
                  isLoadingAction: _vm.isLoading,
                  onSubmitAction: _vm.onSubmitForm,
                },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }