<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('B2B.editB2bIntegration') }}
      div(v-else)  {{ $t('B2B.addNewB2bIntegration') }}

    template(slot="content")
      Loading(v-if="isFetchingInfo")
      form.Form(
        v-else,
        @submit.prevent="onSubmitForm"
        id="integration-b2b-config-form"
      )
        .Form-item.required
          label.Form-item-label {{ $t('B2B.IntegrationName') }}
          .control
            input.txt(
            id="integration-b2b-config-field-name",
            name="name",
            :class="{ 'is-danger': veeErrors.has('name') }",
            ref="firstInput"
            v-validate="validateRules"
            :data-vv-as="$t('B2B.IntegrationName')"
            :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
            v-model="form.name")
            Loading.input-loading(v-if="isNameControlLoading",theme="disabled")
            span.input-loading.c-success(v-if="isValidateName &&!isNameControlLoading",)
              Icon.icon-check(name="icon-check")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('name')",
          v-html="veeErrors.first('name')"
          )

        .Form-item.required

          label.Form-item-label {{ $t('Dictionary.Supplier') }}
          .control
            custom-search(
              component-name="b2b-config-supplier"
              name="supplier",
              v-model="form.supplier",
              :data-vv-as="$t('Dictionary.Supplier')",
              v-validate="{'required': supplierList.length === 0}",
              :inputClass="{ 'is-danger': veeErrors.has('supplier') }"
              ref="focusableInput"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :loading="isSearchSupplierForB2bIntegration"
              :placeholder="$t('Global.search_Placeholder')"
            )
              template(slot="no-options") {{ $t('Global.search_NoResults') }}

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('supplier')"
          ) {{ veeErrors.first('supplier') }}

          .selected-items
            Button(
              v-for="(supplier, index) in supplierList",
              :key="supplier.id",
              @click="removeSupplierItem(supplier)"
              :id="`selected-supplier-item-button-${index}`"
              seashell
              size="medium"
            )
             span {{ supplier.name }}
              Icon.m-left-5(
                name="icon-popup-close"
                :id="`selected-supplier-item-delete-${index}`"
              )

    template(slot="footer")
      Button(
        primary
        size="medium"
        type="submit",
        form="integration-b2b-config-form",
        id="integration-b2b-config-button"
        :isLoading="isActionsLoading",
        :disabled="isActionsLoading || supplierList.length === 0",
      )
        span(v-if="isEdit") {{ $t('B2B.editB2bIntegration') }}
        span(v-else)  {{ $t('B2B.addNewB2bIntegration') }}
</template>

<script>

import { mapFields } from 'vee-validate'
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'IntegrationB2bConfigForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      selectOptions: [],
      supplierList: [],
      form: {
        name: null,
        supplier: null
      }
    }
  },

  computed: {

    isEdit () {
      return this.status === 'edit'
    },

    itemId () {
      return this.$route.params.id || null
    },

    integrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    ...mapFields({
      name: 'name'
    }),

    isFetchingInfo () {
      return this.$wait.is('fetchingB2bDefinitionInfo')
    },

    isSearchSupplierForB2bIntegration () {
      return this.$wait.is('searchSupplierForB2bIntegration')
    },

    isNameControlLoading () {
      return this.name.pending
    },

    isValidateName () {
      return this.name.valid && !this.isNameControlLoading
    },

    validateRules () {
      const url = this.isEdit ? `integration/b2b/config/definition/check/name?Id=${this.itemId}` : `integration/b2b/config/definition/check/name`
      return `required|max:32|very_singularity:${url},Name`
    },

    isActionsLoading () {
      return this.$wait.is(['createB2bIntegration', 'updateB2bIntegration', 'onSubmitB2BConfig'])
    },

    supplierListIsEmpty () {
      return this.supplierList.length === 0
    }
  },

  methods: {

    ...mapActions('IntegrationB2b', [
      'searchSupplierForB2bIntegration',
      'fetchingB2bDefinitionList',
      'createB2bIntegration',
      'updateB2bIntegration',
      'fetchingB2bDefinitionInfo'
    ]),

    ...mapActions('Notify', ['notifyShow']),

    getOptions (search) {
      this.searchSupplierForB2bIntegration(search)
        .then(res => {
          if (res) {
            this.selectOptions = res.data.supplierList
          }
        })
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    removeSupplierItem (supplier) {
      this.supplierList.splice(this.supplierList.indexOf(supplier), 1)
      this.$nextTick(() => {
        this.form.supplier = null
      })
    },

    async newB2bConfig () {
      const payload = {
        name: this.form.name,
        supplierIdList: this.supplierList.map(supplier => supplier.id),
        integrationProductId: this.integrationProductId
      }
      const res = await this.createB2bIntegration(payload)
      if (res) {
        const message = this.$t('Global.successCreateMessage', { field: this.form.name })
        this.closePopup()
        this.notifyShow({ message })
        this.fetchingB2bDefinitionList({
          integrationProductId: this.integrationProductId
        })
      }
    },

    async updateB2bConfig () {
      const payload = {
        name: this.form.name,
        supplierIdList: this.supplierList.map(supplier => supplier.id),
        id: this.itemId
      }
      const res = await this.updateB2bIntegration(payload)
      if (res) {
        const message = this.$t('Global.successUpdateMessage', { field: this.form.name })
        this.closePopup()
        this.notifyShow({ message })
        this.fetchingB2bDefinitionList({
          integrationProductId: this.integrationProductId
        })
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      if (this.isEdit) {
        await this.updateB2bConfig()
      } else {
        await this.newB2bConfig()
      }
    }, 'onSubmitB2BConfig')
  },

  watch: {
    'form.supplier' (val) {
      if (!val || this.supplierList.findIndex(supplier => supplier.id === val.id) > -1) return
      this.supplierList.push(val)
    }
  },

  async mounted () {
    if (this.isEdit) {
      const itemData = await this.fetchingB2bDefinitionInfo(this.itemId)
      if (!itemData) return
      const intB2BDefinition = itemData.data.intB2BDefinition
      this.form.name = intB2BDefinition.name
      this.form.supplier = intB2BDefinition.supplierList[0]
      this.supplierList = [...intB2BDefinition.supplierList]
    }
  }
}
</script>

<style lang="scss" scoped>

  .selected-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>
