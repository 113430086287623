var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "Form",
      attrs: { id: "integration-protel-integrator-store-grop-setting-form" },
    },
    [
      _c("div", { staticClass: "Form-row" }, [
        _vm.isTypeDigitalPlanet
          ? _c("div", { staticClass: "Form-col" }, [
              _c("div", { staticClass: "Form-item firstname required" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Integration.StoreGroup_FormField_corporateCode")
                    )
                  ),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.corporateCode,
                        expression: "form.corporateCode",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:128",
                        expression: "'required|max:128'",
                      },
                    ],
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("corporateCode") },
                    attrs: {
                      name: "corporateCode",
                      autocomplete: "off",
                      "data-vv-as": _vm.$t(
                        "Integration.StoreGroup_FormField_corporateCode"
                      ),
                      type: "text",
                      id: "input-integration-form-field-corporate-code",
                    },
                    domProps: { value: _vm.form.corporateCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "corporateCode", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("corporateCode"),
                        expression: "veeErrors.has('corporateCode')",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                  },
                  [_vm._v(_vm._s(_vm.veeErrors.first("corporateCode")))]
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item username" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_userName"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.username,
                    expression: "form.username",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:64",
                    expression: "'max:64'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("new-username") },
                attrs: {
                  name: "new-username",
                  autocomplete: "off",
                  placeholder: _vm.$t(
                    "Integration.StoreGroup_FormField_userName"
                  ),
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_userName"
                  ),
                  type: "text",
                  id: "input-settings-form-field-username",
                },
                domProps: { value: _vm.form.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "username", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("new-username"),
                    expression: "veeErrors.has('new-username')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("new-username")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "Form-col m-left-25" }, [
          _c("div", { staticClass: "Form-item password" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_password"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:32",
                    expression: "'max:32'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("new-password") },
                attrs: {
                  name: "new-password",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_password"
                  ),
                  placeholder: _vm.form.id
                    ? "********"
                    : _vm.$t("Integration.StoreGroup_FormField_Password"),
                  type: "password",
                  id: "input-settings-form-field-password",
                },
                domProps: { value: _vm.form.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "password", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("new-password"),
                    expression: "veeErrors.has('new-password')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("new-password")))]
            ),
          ]),
        ]),
      ]),
      _vm.isPortalPlus
        ? _c("div", { staticClass: "Form-row" }, [
            _c("div", { staticClass: "Form-col" }, [
              _c("div", { staticClass: "Form-item apiAddress" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Integration.StoreGroup_FormField_ApiAddress")
                    )
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c("customTextInput", {
                      class: { "is-danger": _vm.veeErrors.has("apiAddress") },
                      attrs: {
                        name: "apiAddress",
                        error: _vm.veeErrors.has("apiAddress"),
                        "data-vv-as": _vm.$t(
                          "Integration.StoreGroup_FormField_ApiAddress"
                        ),
                        placeholder: _vm.$t(
                          "Integration.StoreGroup_FormField_apiAddress_Placeholder"
                        ),
                        id: "input-settings-form-field-apiAddress",
                      },
                      model: {
                        value: _vm.form.apiAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "apiAddress", $$v)
                        },
                        expression: "form.apiAddress",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("apiAddress"),
                          expression: "veeErrors.has('apiAddress')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("apiAddress") },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm.isPortalPlus
              ? _c("div", { staticClass: "Form-col form-item-group" }, [
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Integration.StoreGroup_FormField_FolderIntId")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customNumberInput", {
                          class: {
                            "is-danger": _vm.veeErrors.has("folderIntId"),
                          },
                          attrs: {
                            name: "folderIntId",
                            "data-vv-as": _vm.$t(
                              "Integration.StoreGroup_FormField_FolderIntId"
                            ),
                            placeholder: _vm.$t(
                              "Integration.StoreGroup_FormField_FolderIntId_Placeholder"
                            ),
                            error: _vm.veeErrors.has("folderIntId"),
                            id: "input-settings-form-field-folderIntId",
                          },
                          model: {
                            value: _vm.form.folderIntId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "folderIntId", $$v)
                            },
                            expression: "form.folderIntId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("folderIntId"),
                              expression: "veeErrors.has('folderIntId')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("folderIntId"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item m-left-15" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Integration.StoreGroup_FormField_SourceIntId")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customNumberInput", {
                          class: {
                            "is-danger": _vm.veeErrors.has("sourceIntId"),
                          },
                          attrs: {
                            name: "sourceIntId",
                            error: _vm.veeErrors.has("sourceIntId"),
                            "data-vv-as": _vm.$t(
                              "Integration.StoreGroup_FormField_SourceIntId"
                            ),
                            placeholder: _vm.$t(
                              "Integration.StoreGroup_FormField_SourceIntId_Placeholder"
                            ),
                            id: "input-settings-form-field-sourceIntId",
                          },
                          model: {
                            value: _vm.form.sourceIntId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sourceIntId", $$v)
                            },
                            expression: "form.sourceIntId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("sourceIntId"),
                              expression: "veeErrors.has('sourceIntId')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("sourceIntId"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.extraFields.length > 0
        ? [
            _c(
              "div",
              { staticClass: "Form-extra-fields" },
              _vm._l(_vm.extraFields, function (extraField) {
                return _c(
                  "div",
                  {
                    staticClass: "Form-item",
                    class: { required: extraField.required },
                  },
                  [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            `Integration.StoreGroup_FormField_${extraField.key}`
                          )
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      extraField.type === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form[extraField.key],
                                expression: "form[extraField.key]",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: extraField.validation,
                                expression: "extraField.validation",
                              },
                            ],
                            staticClass: "txt",
                            class: {
                              "is-danger": _vm.veeErrors.has(extraField.key),
                              "default-text": !!extraField.defaultText,
                            },
                            style: _vm.generateInputStyle(extraField),
                            attrs: {
                              id: `input-integration-form-field-${extraField.key}`,
                              name: extraField.key,
                              autocomplete: extraField.autocomplete,
                              "data-vv-as": _vm.$t(
                                `Integration.StoreGroup_FormField_${extraField.key}`
                              ),
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.form[extraField.key])
                                ? _vm._i(_vm.form[extraField.key], null) > -1
                                : _vm.form[extraField.key],
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form[extraField.key],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        extraField.key,
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        extraField.key,
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, extraField.key, $$c)
                                }
                              },
                            },
                          })
                        : extraField.type === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form[extraField.key],
                                expression: "form[extraField.key]",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: extraField.validation,
                                expression: "extraField.validation",
                              },
                            ],
                            staticClass: "txt",
                            class: {
                              "is-danger": _vm.veeErrors.has(extraField.key),
                              "default-text": !!extraField.defaultText,
                            },
                            style: _vm.generateInputStyle(extraField),
                            attrs: {
                              id: `input-integration-form-field-${extraField.key}`,
                              name: extraField.key,
                              autocomplete: extraField.autocomplete,
                              "data-vv-as": _vm.$t(
                                `Integration.StoreGroup_FormField_${extraField.key}`
                              ),
                              type: "radio",
                            },
                            domProps: {
                              checked: _vm._q(_vm.form[extraField.key], null),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.form, extraField.key, null)
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form[extraField.key],
                                expression: "form[extraField.key]",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: extraField.validation,
                                expression: "extraField.validation",
                              },
                            ],
                            staticClass: "txt",
                            class: {
                              "is-danger": _vm.veeErrors.has(extraField.key),
                              "default-text": !!extraField.defaultText,
                            },
                            style: _vm.generateInputStyle(extraField),
                            attrs: {
                              id: `input-integration-form-field-${extraField.key}`,
                              name: extraField.key,
                              autocomplete: extraField.autocomplete,
                              "data-vv-as": _vm.$t(
                                `Integration.StoreGroup_FormField_${extraField.key}`
                              ),
                              type: extraField.type,
                            },
                            domProps: { value: _vm.form[extraField.key] },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  extraField.key,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has(extraField.key),
                            expression: "veeErrors.has(extraField.key)",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first(extraField.key)))]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _c("div", { staticClass: "Form-row" }, [
        _c("div", { staticClass: "Form-col form-submit" }, [
          _c(
            "div",
            { staticClass: "Form-item submit" },
            [
              _c(
                "Button",
                {
                  staticClass: "uppercase",
                  attrs: {
                    type: "submit",
                    primary: "",
                    size: "small",
                    form: "integration-store-grop-setting-form",
                    id: "btn-protel-integration-submit-store-group",
                    isLoading: _vm.isFormSubmitting || _vm.isLoading,
                    disabled:
                      _vm.isFormSubmitting ||
                      _vm.isLoading ||
                      _vm.disableSubmit,
                  },
                  on: { click: _vm.onSubmitStoreGroupIntegration },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Integration.User_FormField_submit"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }