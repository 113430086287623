var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            staticClass: "m-left-10",
            attrs: {
              links: _vm.PageHeaderLinks,
              translationGroup: "Accounting",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            staticClass: "m-bottom-15",
            attrs: {
              list: _vm.IntStoreGroupList,
              selectedItem: _vm.selectedStoreGroup,
              selectedKey: "storeGroupId",
              fieldKey: "storeGroupName",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.typeList,
              selectedItem: _vm.selectedType,
              useUpperCase: false,
              fieldKey: "name",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeType },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingData
            ? _c("TableLoading")
            : _c("IntegrationTable", {
                attrs: {
                  fields: _vm.integrationFields,
                  data: _vm.intStoreList,
                  isLoadingAction: _vm.isLoading,
                },
                on: { onChange: _vm.updateItem },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }