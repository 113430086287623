<template lang="pug">
form.Form(
    id="integration-protel-integrator-store-grop-setting-form")
    .Form-row
      .Form-col(v-if="isTypeDigitalPlanet")
        .Form-item.firstname.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_corporateCode') }}
          .control
            input.txt(
            name="corporateCode"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_corporateCode')"
            v-model="form.corporateCode"
            v-validate="'required|max:128'"
            :class="{ 'is-danger': veeErrors.has('corporateCode') }"
            type="text"
            id="input-integration-form-field-corporate-code")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('corporateCode')")
            | {{ veeErrors.first('corporateCode') }}
      .Form-col
        .Form-item.username
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_userName') }}
          .control
            input.txt(
            name="new-username"
            autocomplete="off"
            :placeholder="$t('Integration.StoreGroup_FormField_userName')"
            :data-vv-as="$t('Integration.StoreGroup_FormField_userName')"
            v-model="form.username"
            v-validate="'max:64'"
            :class="{ 'is-danger': veeErrors.has('new-username') }"
            type="text"
            id="input-settings-form-field-username")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('new-username')")
            | {{ veeErrors.first('new-username') }}

      .Form-col.m-left-25
        .Form-item.password
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_password') }}
          .control
            input.txt(
            name="new-password"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_password')"
            v-model="form.password"
            :placeholder="form.id ? '********' : $t('Integration.StoreGroup_FormField_Password')"
            v-validate="'max:32'"
            :class="{ 'is-danger': veeErrors.has('new-password') }"
            type="password"
            id="input-settings-form-field-password")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('new-password')")
            | {{ veeErrors.first('new-password') }}

    .Form-row(v-if="isPortalPlus")
      .Form-col
        .Form-item.apiAddress
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_ApiAddress') }}
          .control
            customTextInput(
              name="apiAddress"
              :error="veeErrors.has('apiAddress')"
              :data-vv-as="$t('Integration.StoreGroup_FormField_ApiAddress')"
              v-model="form.apiAddress"
              :placeholder="$t('Integration.StoreGroup_FormField_apiAddress_Placeholder')"
              :class="{ 'is-danger': veeErrors.has('apiAddress') }"
              id="input-settings-form-field-apiAddress")

            showValidateError(
              v-show="veeErrors.has('apiAddress')"
              :errorName="veeErrors.first('apiAddress')"
            )
            
      .Form-col.form-item-group(v-if="isPortalPlus")
        .Form-item
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_FolderIntId') }}
          .control
            customNumberInput(
              name="folderIntId"
              :data-vv-as="$t('Integration.StoreGroup_FormField_FolderIntId')"
              v-model="form.folderIntId"
              :placeholder="$t('Integration.StoreGroup_FormField_FolderIntId_Placeholder')"
              :error="veeErrors.has('folderIntId')"
              :class="{ 'is-danger': veeErrors.has('folderIntId') }"
              id="input-settings-form-field-folderIntId")
          
            showValidateError(
              v-show="veeErrors.has('folderIntId')"
              :errorName="veeErrors.first('folderIntId')"
            )
    
        .Form-item.m-left-15
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_SourceIntId') }}
          .control
            customNumberInput(
              name="sourceIntId"
              :error="veeErrors.has('sourceIntId')"
              :data-vv-as="$t('Integration.StoreGroup_FormField_SourceIntId')"
              v-model="form.sourceIntId"
              :placeholder="$t('Integration.StoreGroup_FormField_SourceIntId_Placeholder')"
              :class="{ 'is-danger': veeErrors.has('sourceIntId') }"
              id="input-settings-form-field-sourceIntId")
          
            showValidateError(
              v-show="veeErrors.has('sourceIntId')"
              :errorName="veeErrors.first('sourceIntId')"
            )

    template(v-if="extraFields.length > 0")
      .Form-extra-fields
        .Form-item(v-for="extraField in extraFields", :class="{ required: extraField.required }")
          label.Form-item-label {{ $t(`Integration.StoreGroup_FormField_${extraField.key}`) }}
          .control
            input.txt(
            :id="`input-integration-form-field-${extraField.key}`"
            :name="extraField.key"
            :autocomplete="extraField.autocomplete"
            :data-vv-as="$t(`Integration.StoreGroup_FormField_${extraField.key}`)"
            v-model="form[extraField.key]"
            v-validate="extraField.validation"
            :class="{ 'is-danger': veeErrors.has(extraField.key), 'default-text': !!extraField.defaultText }"
            :type="extraField.type"
            :style="generateInputStyle(extraField)")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has(extraField.key)")
            | {{ veeErrors.first(extraField.key) }}

    .Form-row
      .Form-col.form-submit
        .Form-item.submit
          Button.uppercase(
            type="submit",
            primary
            size="small"
            form="integration-store-grop-setting-form"
            id="btn-protel-integration-submit-store-group"
            @click="onSubmitStoreGroupIntegration"
            :isLoading="isFormSubmitting || isLoading",
            :disabled="isFormSubmitting || isLoading || disableSubmit"
          )
            span {{ $t('Integration.User_FormField_submit') }}
</template>

<script>

import CustomSelect from '@/view/global/custom-select'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StoreGroupConfigForm',

  components: {
    CustomSelect
  },

  data () {
    return {
      mutatedData: []
    }
  },

  props: {
    form: {
      type: Object,
      default: {}
    },
    isTypeDigitalPlanet: {
      type: Boolean,
      default: false
    },
    isPortalPlus: {
      type: Boolean,
      default: false
    },
    taxNumber: {
      type: String,
      default: null
    },
    isLoadingAction: {
      type: Boolean,
      default: false
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    extraFields: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters('EConversionStoreGroupConfig', [
      'AliasData'
    ]),
    isFormSubmitting () {
      return this.$wait.is('formSubmitting')
    },

    isLoading () {
      return this.isLoadingAction
    }
  },

  methods: {
    ...mapActions('EConversionStoreGroupConfig', [
      'getProtelIntegratorAliasData'
    ]),

    async onSubmitStoreGroupIntegration () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) {
        await this.$wait.end('formSubmitting')
        return
      }
      await this.submit()
    },

    async getAliasData () {
      await this.getProtelIntegratorAliasData(this.taxNumber)
    },

    async submit () {
      this.$emit('onSubmitAction', this.form)
    },

    generateInputStyle (field) {
      return {
        'background': `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="30"><text x="0" y="19" style="font: 12px; opacity: 0.5;">${field.defaultText}</text></svg>') no-repeat`,
        'background-position': 'center right',
        'background-color': 'white',
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .Form {
    border: 1px solid $color-gray;
    padding: 25px;
    border-radius: $border-radius;

    &-extra-fields {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      row-gap: 20px;
      column-gap: 40px;

      .Form-item {
        width: calc(50% - 20px);
      }
    }

    &-col.form-submit {
      margin-right: 0 !important;

    }
    &-item.submit {
      width: 150px;
      button {
        width: 100%;
      }
    }

    .Form-row {
      display: flex;

        .Form-col {
          width: 50%;
          .form-submit {
            display: flex;
            justify-content: flex-end;
          }
        }

        .Form-col:first-child {
          margin-right: 20px;
        }
      }

      .Form-row:not(:first-child) {

        .Form-col {
          margin-top: 20px;
      }
    }
  }
  .taxNumberValidation {
    color: $color-warning
  }
  .custom-select {
    width: 66%;
  }
  .select-dropdown-custom-selected {
    width: 100% !important;
  }
  .select-dropdown-custom-options.upside {
    width: 100% !important;
  }
  .select-dropdown-custom-options.normal {
    width: 100% !important;
  }
  .form-submit {
    display: flex !important;
    justify-content: flex-end !important;
  }

</style>
