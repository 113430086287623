<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs.m-left-10(
      :links="pageTabLinks",
      translationGroup="WarehouseManagement"
    )
  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="IntWmsStoreGroupList",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      selectedKey="storeGroupId",
      fieldKey="storeGroupName"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingSettingInfo")
    SettingsForm(
      v-else,
      :form="form",
      :isLoadingAction="isLoading"
      :onSubmitAction="onSubmitForm"
    )
      template(slot="extraField")
        .checkField
          CustomCheckbox.m-right-25(
            v-model="form.syncApprovedOrders",
            :label="$t('Integration.syncApprovedOrders')"
          )

          CustomCheckbox(
            v-model="form.syncTransferRequests",
            :label="$t('Integration.syncTransferRequests')"
          )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import SettingsForm from '@/view/pages/Integration/WarehouseManagement/Axata/components'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'WarehouseManagementAxataStoreGroup',

  data () {
    return {
      form: {
        companyNumber: null,
        username: null,
        password: null,
        apiAddress: null,
        syncApprovedOrders: false,
        syncTransferRequests: false,
      }
    }
  },

  components: {
    ToggleOverGroups,
    SettingsForm
  },

  computed: {
    ...mapGetters('IntegrationWarehouseManagementAxata', ['pageTabLinks', 'selectedStoreGroup','IntWmsStoreGroupList']),
    ...mapGetters('Settings', ['UserInfo']),

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isFetchingSettingInfo () {
      return this.$wait.is(['fetchWMSConfigStoreGroup', 'configPageLoading'])
    },

    isLoading () {
      return this.$wait.is(['storeGroupConfigWMSAxata'])
    }
  },

  methods: {
    ...mapActions('IntegrationWarehouseManagementAxata', [
      'updateWMSConfigStoreGroup',
      'fetchWMSConfigStoreGroup',
      'fetchWMSConfigStoreGroupAll',
    ]),

    ...mapMutations('IntegrationWarehouseManagementAxata', ['SET_SELECTED_STORE_GROUP']),

    getIntegrationStoreGroupWithParams () {
      const params = {
        StoreGroupId: this.selectedStoreGroup.storeGroupId,
        IntegrationProductId: this.IntegrationProductId
      }
      this.fetchWMSConfigStoreGroup(params)
        .then(res => {
          this.form = res.data.intWmsStoreGroup
          this.$validator.reset()
        })
    },

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async onChangeStoreGroup (storeGroup) {
      this.SET_SELECTED_STORE_GROUP(storeGroup)
      await this.getIntegrationStoreGroupWithParams()
    },

    onSubmitForm: vueWaitLoader(async function (form) {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        let params = {
          ...form,
          isPasswordFilled: !!form.password,
          storeGroupId: this.selectedStoreGroup.storeGroupId,
        }
        const res = await this.updateWMSConfigStoreGroup(params)
        if (!res) return
        const message = this.$t('Integration.StoreGroupSuccessUpdated')
        this.notifyShow({ message })
        this.getIntegrationStoreGroupWithParams()
      })
    }, 'storeGroupConfigWMSAxata')
  },

  async mounted () {
    this.$wait.start('configPageLoading')
    await this.fetchWMSConfigStoreGroupAll({
      IntegrationProductId: this.IntegrationProductId
    })
    const defaultStore = this.IntWmsStoreGroupList.find(_ => _.isStoreGroupDefault)
    const selectedStoreGroup = this.selectedStoreGroup.hasOwnProperty('storeGroupId') ? this.selectedStoreGroup : defaultStore
    await this.onChangeStoreGroup(selectedStoreGroup)
    this.$wait.end('configPageLoading')
  }
}
</script>

<style lang="scss">

  .checkField {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $input-size;
    margin-top: 27.5px; // for label and lineheight
    margin-bottom: 48px;

  }

</style>
