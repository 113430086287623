
<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageHeaderTabs.m-left-10(
      :links="PageHeaderLinks",
      translationGroup="Accounting"
    )
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-15(
        :list="IntStoreGroupList",
        :selectedItem="selectedStoreGroup",
        selectedKey="storeGroupId",
        @delegateChangeSelectedItem="onChangeStoreGroup",
        fieldKey="storeGroupName"
      )
  template(slot="toolbar-left")
    ToggleOverGroups(
      :list="typeList"
      :selectedItem="selectedType"
      :useUpperCase="false"
      @delegateChangeSelectedItem="onChangeType"
      fieldKey="name"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingData")
    IntegrationTable(
      v-else,
      :fields="integrationFields",
      @onChange="updateItem"
      :data="intStoreList"
      :isLoadingAction="isLoading"
    )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import IntegrationTable from '@/view/pages/Integration/components/Table'
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'IntegrationAccountingStore',

  components: {
    ToggleOverGroups,
    IntegrationTable
  },

  data () {
    return {
      selectedStoreGroup: {},
      intStoreList: [],
      selectedType: {},
      typeList: [
        {
          name: this.$t('Integration.Item_Type_Invoice'),
          id: 1,
          shortName: 'Invoice'
        },
        {
          name: this.$t('Integration.Item_Type_Transfer'),
          id: 2,
          shortName: 'Transfer'
        },
        {
          name: this.$t('Integration.Item_Type_Inventory'),
          id: 3,
          shortName: 'Inventory'
        },
        {
          name: this.$t('Integration.Item_Type_Potantial_S'),
          id: 4,
          shortName: 'Potantial_S'
        }
      ]
    }
  },

  computed: {

    ...mapGetters('IntegrationAccountingLogo', [
      'PageHeaderLinks',
      'IntStoreGroupList'
    ]),

    integrationFields () {
      return [
        {
          name: 'itemGroupName',
          text: this.$t('Integration.ItemGroup_InputField_ItemGroup_Name')
        },
        { name: 'accountCode',
          isEditable: true,
          text: this.$t('Integration.ItemGroup_InputField_ItemGroup_AccountCode'),
          input: {
            validateRule: 'max:32'
          }
        }
      ]
    },

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isFetchingData () {
      return this.$wait.is(['pageLoading', 'getIntItemGroupList'])
    },

    isLoading () {
      return this.$wait.is(['saveIntGroup'])
    }
  },

  methods: {

    ...mapActions('IntegrationAccountingLogo', [
      'fetchIntStoreGroupList',
      'getIntItemGroupList',
      'saveIntItemGroup'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Settings', [
      'setDefaultSettings'
    ]),

    onChangeStoreGroup (storeGroup) {
      this.selectedStoreGroup = storeGroup
      const params = {
        settings: {
          SelectedStoreGroupIdOnLogo: this.selectedStoreGroup.storeGroupId
        }
      }
      this.setDefaultSettings(params)
      if (!storeGroup.id) {
        this.$router.push({
          name: this.PageHeaderLinks[0]
        })
        return
      }
      this.getFilterData()
    },

    getFilterData () {
      this.getIntItemGroupList({
        IntStoreGroupId: this.selectedStoreGroup.id,
        Type: this.selectedType.id
      })
        .then(res => {
          if (!res) return
          this.intStoreList = res.data.intItemGroupList
        })
    },
    onChangeType (type) {
      this.selectedType = type
      this.getFilterData()
    },

    setDefaultStoreGroup () {
      let selectedStoreGroup = this.IntStoreGroupList.find(storeGroup => storeGroup.isLastSelected)
      if (!selectedStoreGroup) selectedStoreGroup = this.IntStoreGroupList.find(storeGroup => storeGroup.isStoreGroupDefault)
      this.onChangeStoreGroup(selectedStoreGroup)
      if (!this.selectedStoreGroup) this.onChangeStoreGroup(this.IntStoreGroupList[0])
    },

    updateItem: vueWaitLoader(async function (row) {
      const payload = {
        intStoreGroupId: this.selectedStoreGroup.id,
        type: this.selectedType.id,
        itemGroupId: row.itemGroupId,
        accountCode: row.accountCode
      }

      await this.saveIntItemGroup(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('Integration.ItemGroupSuccessUpdated', { name: row.itemGroupName })
          this.notifyShow({ message })
        })
    }, 'saveIntGroup')
  },

  async beforeMount () {
    this.$wait.start('pageLoading')
    this.selectedType = this.typeList[0]
    await this.fetchIntStoreGroupList({
      IntegrationProductId: this.IntegrationProductId
    })
    await this.setDefaultStoreGroup()
    this.$wait.end('pageLoading')
  }
}
</script>
