import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    configList: []
  },

  getters: {
    configList: state => state.configList
  },

  actions: {
    fetchSimpraPosStoreGroupList({ commit }, params) {
      const loadingName = 'fetchSimpraPosStoreGroupList'
      const results = requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/pos/config/all', { params: { ...params } })
        },
        success: result => {
          commit('SET_INT_STORE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async saveIntegrationSimprapropertyKey({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntegrationSimprapropertyKey',
        methodName: 'saveIntegrationSimprapropertyKey',
        method: () => {
          return HTTP.post('integration/pos/config', payload)
        },
        success: result => result
      })
      return results
    },

    async setPosMenuItemSyncWithStockItemStatus({ commit }, payload) {
      const results = await requestHandler({
        title: 'setPosMenuItemSyncWithStockItemStatus',
        methodName: 'setPosMenuItemSyncWithStockItemStatus',
        method: () => {
          return HTTP.put('integration/pos/config/simprapos/setposmenuitemsyncwithstockitemstatus', payload)
        },
        success: result => result
      })
      return results
    },

    async getPosMenuItemSyncWithStockItemStatus() {
      const results = await requestHandler({
        title: 'getPosMenuItemSyncWithStockItemStatus',
        methodName: 'getPosMenuItemSyncWithStockItemStatus',
        method: () => {
          return HTTP.get('integration/pos/config/simprapos/posmenuitemtransferwithstockitemstatus')
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_INT_STORE_LIST(state, data) {
      state.configList = data.configList
    },
    RESET_INT_STORE_LIST(state) {
      state.configList = []
    }
  }

}
