var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [_vm._v(_vm._s(_vm.$t("B2B.editB2bIntegration")))])
          : _c("div", [
              _vm._v(" " + _vm._s(_vm.$t("B2B.addNewB2bIntegration"))),
            ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isFetchingInfo
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "integration-b2b-config-form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("B2B.IntegrationName"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.validateRules,
                              expression: "validateRules",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          ref: "firstInput",
                          staticClass: "txt",
                          class: { "is-danger": _vm.veeErrors.has("name") },
                          attrs: {
                            id: "integration-b2b-config-field-name",
                            name: "name",
                            "data-vv-as": _vm.$t("B2B.IntegrationName"),
                            "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                          },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm.isNameControlLoading
                          ? _c("Loading", {
                              staticClass: "input-loading",
                              attrs: { theme: "disabled" },
                            })
                          : _vm._e(),
                        _vm.isValidateName && !_vm.isNameControlLoading
                          ? _c(
                              "span",
                              { staticClass: "input-loading c-success" },
                              [
                                _c("Icon", {
                                  staticClass: "icon-check",
                                  attrs: { name: "icon-check" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("p", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("name"),
                          expression: "veeErrors.has('name')",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                      domProps: {
                        innerHTML: _vm._s(_vm.veeErrors.first("name")),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Dictionary.Supplier"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "custom-search",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: _vm.supplierList.length === 0,
                                },
                                expression:
                                  "{'required': supplierList.length === 0}",
                              },
                            ],
                            ref: "focusableInput",
                            attrs: {
                              "component-name": "b2b-config-supplier",
                              name: "supplier",
                              "data-vv-as": _vm.$t("Dictionary.Supplier"),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("supplier"),
                              },
                              "on-search": _vm.getOptions,
                              options: _vm.selectOptions,
                              label: "name",
                              loading: _vm.isSearchSupplierForB2bIntegration,
                              placeholder: _vm.$t("Global.search_Placeholder"),
                            },
                            model: {
                              value: _vm.form.supplier,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "supplier", $$v)
                              },
                              expression: "form.supplier",
                            },
                          },
                          [
                            _c("template", { slot: "no-options" }, [
                              _vm._v(_vm._s(_vm.$t("Global.search_NoResults"))),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("supplier"),
                            expression: "veeErrors.has('supplier')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("supplier")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "selected-items" },
                      _vm._l(_vm.supplierList, function (supplier, index) {
                        return _c(
                          "Button",
                          {
                            key: supplier.id,
                            attrs: {
                              id: `selected-supplier-item-button-${index}`,
                              seashell: "",
                              size: "medium",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeSupplierItem(supplier)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              [
                                _vm._v(_vm._s(supplier.name)),
                                _c("Icon", {
                                  staticClass: "m-left-5",
                                  attrs: {
                                    name: "icon-popup-close",
                                    id: `selected-supplier-item-delete-${index}`,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "medium",
                type: "submit",
                form: "integration-b2b-config-form",
                id: "integration-b2b-config-button",
                isLoading: _vm.isActionsLoading,
                disabled: _vm.isActionsLoading || _vm.supplierList.length === 0,
              },
            },
            [
              _vm.isEdit
                ? _c("span", [_vm._v(_vm._s(_vm.$t("B2B.editB2bIntegration")))])
                : _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("B2B.addNewB2bIntegration"))),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }