<template lang="pug">
Page
  template(slot="header-left-dropdown")
    div
  template(slot="content-center")
    TableLoading(v-if="isFetchingData || isUpdatingForm || isCheckingForm")
    StoreGroupConfigForm(
      v-else
      :form="form",
      :useAlias="false"
      :isLoadingAction="isFetchingData"
      :disableSubmit="(this.iBarInfo && form.username === iBarInfo.userName && form.password === iBarInfo.password) || isUpdatingForm || isCheckingForm"
      @onSubmitAction="onSubmitForm"
      )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import StoreGroupConfigForm from '@/view/pages/Integration/components/SettingsForm/storeGroupConfigForm'
import { vueWaitLoader } from '@/utils/baseOperations'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'iBar',
  /**
  **/
  data () {
    return {
      form: {
        username: null,
        password: null
      },
      storeGroups: [],
      selectedStore: null
    }
  },
  /**
  **/
  components: {
    ToggleOverGroups,
    StoreGroupConfigForm
  },

  computed: {
    ...mapGetters('Integration', ['pageTabLinks', 'iBarInfo']),
    ...mapGetters('Settings', ['UserInfo']),

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isFetchingData () {
      return this.$wait.is(['checkIntegrationIBarStatus'])
    },
    isUpdatingForm () {
      return this.$wait.is(['storeGroupConfigProtelIntegrator'])
    },
    isCheckingForm () {
      return this.$wait.is(['checkFormData'])
    }
  },

  methods: {
    ...mapActions('Integration', [
      'updateIBarIntegrationStatus',
      'checkIntegrationIBarStatus'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    resetForm () {
      this.form.username = null
      this.form.password = null
    },

    checkForm: vueWaitLoader(async function () {
      if (this.iBarInfo) {
        this.form.username = this.iBarInfo.userName
        this.form.password = this.iBarInfo.password
      } 
    }, 'checkFormData'),

    onSubmitForm: vueWaitLoader(async function (form) {
      if (form.username === '') this.resetForm()
      if (form.password === '') form.password = null
      let params = {
        username: form.username,
        password: form.password,
        isActive: true
      }
      const res = await this.updateIBarIntegrationStatus(params)
      if (!res) return
      const message = this.$t('Integration.StoreGroupSuccessUpdated')
      this.notifyShow({ message })
      await this.checkIntegrationIBarStatus()
      await this.checkForm()
    }, 'storeGroupConfigProtelIntegrator')
  },

  async mounted () {
    this.$wait.start('configPageLoading')
    await this.checkIntegrationIBarStatus()
    await this.checkForm()
    this.$wait.end('configPageLoading')
  }
}
</script>

<style lang="scss">

  .checkField {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $input-size;
    margin-top: 27.5px; // for label and lineheight
    margin-bottom: 48px;

  }

</style>
