<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    div
  template(slot="header-right-content")
    template(v-if="isExcelType")
      button.downloadlink(
        @click="downloadExcel"
        :disabled="isExcelLoading"
      )
        Loading(v-if="isExcelLoading", theme="disable")
        Icon(v-if="!isExcelLoading" name="icon-excel-download")
        span {{ $t('Integration.downloadTemplate') }}
      a.fake-anchor(
        id="fake-anchor"
        ref="downloadTemplate"
        download="sales-template.xlsx"
      )
      RVCImport

  template(slot="content-center")
    TableLoading(v-if="isFetchingPageData")
    div(v-else)
      template(v-if="isExcelType")
        .flexbox.justify-space-between.align-center
          .left
            .info
              Icon.c-sky-blue.m-right-10(name="icon-info-full")
              span.font-size-small.c-light {{ $t('Integration.excelDescription') }}
          .right
            Button.add-pos-key.m-bottom-20(
              type="button",
              id="btn-integration-generate-pos-key"
              primary,
              size="small",
              :isLoading="isKeyGenerating"
              :disabled="disabledPosKeyButton",
              @click="generatePosKey"
            )
              span {{ $t('Global.Save') }}
      template(v-else)
        .flexbox.justify-space-between
          .left
            Button.add-pos-key.m-bottom-20(
              type="button",
              id="btn-integration-generate-pos-key"
              success,
              :isLoading="isKeyGenerating"
              :disabled="disabledPosKeyButton",
              @click="generatePosKey"
            )
              span {{ $t('Integration.addPosKey') }}

      IntegrationTable(
        v-model="selectedIntegration",
        :fields="integrationFields",
        :data="configList",
        :readOnly="isExcelType",
        ref="posIntegrationTable",
        :toggleSelectedAllIgnoreAction="makeToggleSelectedIgnoreItemFunction",
        :hasAction="!isExcelType"
      )

      portal(to="integrationRowPortal")
        div.flexbox.justify-flex-end(slot-scope="row")
          Button(
            v-if="row.propertyKey",
            variant="btn-icon action-remove",
            type="button",
            :justIcon="true",
            :isLoading="isDeletingPosKey",
            :disabled="isDeletingPosKey",
            iconName="icon-delete",
            id="btn-integration-delete-pos-key"
            @click="deletePosKey(row)"
          )
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import alertbox from '@/mixins/alertbox'
import downloadCSVMixin from '@/mixins/download-actions'

//
import RVCImport from '@/view/pages/Integration/components/RvcImport'
import IntegrationTable from '@/view/pages/Integration/components/Table'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'GenericPos',

  /**
  **/

  components: {
    RVCImport,
    IntegrationTable
  },

  /**
  **/

  mixins: [
    alertbox(),
    downloadCSVMixin
  ],

  /**
  *
  **/

  data () {
    return {
      IntegrationProductId: this.$route.params.IntegrationProductId,
      selectedIntegration: [],
      integrationFields: [
        {
          name: 'storeGroupName',
          text: this.$t('Dictionary.StoreGroup'),
          hasCheckbox: true,
          uniqueKey: 'storeGroupId',
          checkboxDisabled: (row) => !!row.propertyKey
        }
      ]
    }
  },

  computed: {

    PosName () {
      return this.$route.meta.posName
    },

    isExcelType () {
      return this.$route.meta.posName === 'Excel'
    },

    isFetchingPageData () {
      return this.$wait.is('pageLoading')
    },

    isKeyGenerating () {
      return this.$wait.is('keysGenerating')
    },

    isExcelLoading () {
      return this.$wait.is('excelLoading')
    },

    isDeletingPosKey () {
      return this.$wait.is(['deletingPosKey', 'deletePosKeyItem'])
    },

    ...mapGetters('IntegrationSimpraPos', ['configList']),

    disabledPosKeyButton () {
      return this.selectedIntegration.filter(_ => !_.propertyKey).length === 0 || this.isKeyGenerating
    }
  },

  methods: {

    ...mapActions('IntegrationSimpraPos', [
      'fetchSimpraPosStoreGroupList',
      'saveIntegrationSimprapropertyKey'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('IntegrationSimpraPos', ['RESET_INT_STORE_LIST']),

    downloadExcel: vueWaitLoader(async function () {
      const url = 'Sales/export'
      const ref = this.$refs.downloadTemplate
      await this.downloadExcelAction({url, ref})
    }, 'excelLoading'),

    async getConfigList () {
      await this.fetchSimpraPosStoreGroupList({
        IntegrationProductId: this.IntegrationProductId
      })
      this.selectedIntegration = await this.configList.filter(item => item.propertyKey)
    },

    generatePosKey: vueWaitLoader(async function () {
      const payload = {
        integrationProductId: this.IntegrationProductId,
        configList: this.selectedIntegration.filter(_ => !_.propertyKey).map(item => {
          return {
            storeGroupId: item.storeGroupId,
            propertyKey: '[GENERATE]'
          }
        })
      }
      const res = await this.saveIntegrationSimprapropertyKey(payload)
      if (res) {
        const message = this.$t('Integration.generatePosKey')
        this.notifyShow({ message })
        this.getConfigList()
      }
    }, 'keysGenerating'),

    deletePosKey: vueWaitLoader(async function (item) {
      const title = this.isExcelType ? this.$t('Integration.removeExcelKeyConfirmTitle') : this.$t('Integration.removePosKeyConfirmTitle')
      const message = this.isExcelType ? this.$t('Integration.removeExcelKeyConfirmMessage') : this.$t('Integration.removePosKeyConfirmMessage')
      const confirmButtonText = this.isExcelType ? this.$t('Integration.removeExcelKeyOkButton') : this.$t('Integration.removeOKButtonText')
      this.alert({
        title,
        message,
        confirm: {
          text: confirmButtonText,
          action: async (close) => {
            const res = await this.confirmDeletePostKey(item)
            if (res) {
              close()
            }
          }
        }
      })
    }, 'deletePosKeyItem'),

    async confirmDeletePostKey (row) {
      this.$wait.start('deletingPosKey')
      const payload = {
        integrationProductId: this.IntegrationProductId,
        configList: [
          {
            storeGroupId: row.storeGroupId,
            propertyKey: null
          }
        ]
      }
      const res = await this.saveIntegrationSimprapropertyKey(payload)
      if (res) {
        const message = this.$t('Integration.removePosKey')
        this.notifyShow({ message })
        this.getConfigList()
        this.selectedIntegration.splice(this.selectedIntegration.indexOf(row), 1)
      }
      this.$wait.end('deletingPosKey')
      return res
    },

    makeToggleSelectedIgnoreItemFunction (item) {
      return !item.propertyKey
    }
  },

  async beforeMount () {
    this.$wait.start('pageLoading')
    await this.getConfigList()
    if (!this.isExcelType) {
      this.integrationFields.push({ name: 'propertyKey',
        readonlyValue: true,
        text: 'Property Key',
        emptyText: this.$t('Integration.createPosKeyDescription')
      })
    }
    this.$wait.end('pageLoading')
  },

  async beforeDestroy () {
    await this.RESET_INT_STORE_LIST()
  }
}
</script>

<style lang="scss" scoped>

  .add-pos-key {
   max-width: max-content;
  }

  .downloadlink {
    border: 1px solid $color-gray;
    border-radius: $border-radius;
    color: $color-success;
    display: flex;
    height: 54px;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    .icon {
      width: 26px;
      height: 25px;
    }

    span {
      font-weight: normal;
      margin-left: 7px;
    }
  }

  .fake-anchor {
    display: none;
  }
</style>
