var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "success-message" }, [
      _vm._v(_vm._s(_vm.$t("Integration.xeroConnectionSuccess"))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }