
<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageHeaderTabs.m-left-10(
      :links="PageHeaderLinks"
      translationGroup="Accounting"
    )
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-15(
        :list="IntStoreGroupList",
        :selectedItem="selectedStoreGroup",
        selectedKey="storeGroupId",
        @delegateChangeSelectedItem="onChangeStoreGroup",
        fieldKey="storeGroupName"
      )
  template(slot="content-center")
    TableLoading(v-if="isFetchingData")
    IntegrationTable(
      v-else,
      :fields="integrationFields",
      @onChange="updateItem"
      :data="intStoreList"
      :isLoadingAction="isLoading"
    )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import IntegrationTable from '@/view/pages/Integration/components/Table'
//
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'IntegrationAccountingStore',

  components: {
    ToggleOverGroups,
    IntegrationTable
  },

  data () {
    return {
      selectedStoreGroup: {},
      intStoreList: []
    }
  },

  computed: {

    ...mapGetters('IntegrationAccountingLogo', [
      'PageHeaderLinks',
      'IntStoreGroupList'
    ]),

    integrationFields () {
      return [
        {
          name: 'wasteGroupName',
          text: this.$t('Integration.WasteGroup_InputField_WasteGroup_Name')
        },
        { name: 'accountCode',
          isEditable: true,
          text: this.$t('Integration.WasteGroup_InputField_WasteGroup_AccountCode'),
          input: {
            validateRule: 'max:32'
          }
        }
      ]
    },

    isFetchingData () {
      return this.$wait.is(['pageLoading', 'getIntWasteGroupList'])
    },

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isLoading () {
      return this.$wait.is(['saveIntWasteGroupItem'])
    }
  },

  methods: {

    ...mapActions('IntegrationAccountingLogo', [
      'fetchIntStoreGroupList',
      'getIntWasteGroupList',
      'saveIntWasteGroup'
    ]),

    ...mapActions('Settings', [
      'setDefaultSettings'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    onChangeStoreGroup (storeGroup) {
      if (storeGroup === this.selectedStoreGroup) return
      this.selectedStoreGroup = storeGroup
      const params = {
        settings: {
          SelectedStoreGroupIdOnLogo: this.selectedStoreGroup.storeGroupId
        }
      }
      this.setDefaultSettings(params)
      if (!storeGroup.id) {
        this.$router.push({
          name: this.PageHeaderLinks[0]
        })
        return
      }
      this.getIntWasteGroupList({
        IntStoreGroupId: this.selectedStoreGroup.id
      })
        .then(res => {
          if (!res) return
          this.intStoreList = res.data.intWasteGroupList
        })
    },

    setDefaultStoreGroup () {
      let selectedStoreGroup = this.IntStoreGroupList.find(storeGroup => storeGroup.isLastSelected)
      if (!selectedStoreGroup) selectedStoreGroup = this.IntStoreGroupList.find(storeGroup => storeGroup.isStoreGroupDefault)
      this.onChangeStoreGroup(selectedStoreGroup)
    },

    updateItem: vueWaitLoader(async function (row) {
      const payload = {
        intStoreGroupId: this.selectedStoreGroup.id,
        wasteGroupId: row.wasteGroupId,
        accountCode: row.accountCode
      }

      await this.saveIntWasteGroup(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('Integration.WasteGroupSuccessUpdated', { name: row.wasteGroupName })
          this.notifyShow({ message })
        })
    }, 'saveIntWasteGroupItem')
  },

  async beforeMount () {
    this.$wait.start('pageLoading')
    await this.fetchIntStoreGroupList({
      IntegrationProductId: this.IntegrationProductId
    })
    await this.setDefaultStoreGroup()
    this.$wait.end('pageLoading')
  }
}
</script>
