var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _vm.isExcelType
            ? [
                _c(
                  "button",
                  {
                    staticClass: "downloadlink",
                    attrs: { disabled: _vm.isExcelLoading },
                    on: { click: _vm.downloadExcel },
                  },
                  [
                    _vm.isExcelLoading
                      ? _c("Loading", { attrs: { theme: "disable" } })
                      : _vm._e(),
                    !_vm.isExcelLoading
                      ? _c("Icon", { attrs: { name: "icon-excel-download" } })
                      : _vm._e(),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("Integration.downloadTemplate"))),
                    ]),
                  ],
                  1
                ),
                _c("a", {
                  ref: "downloadTemplate",
                  staticClass: "fake-anchor",
                  attrs: { id: "fake-anchor", download: "sales-template.xlsx" },
                }),
                _c("RVCImport"),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingPageData
            ? _c("TableLoading")
            : _c(
                "div",
                [
                  _vm.isExcelType
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flexbox justify-space-between align-center",
                          },
                          [
                            _c("div", { staticClass: "left" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c("Icon", {
                                    staticClass: "c-sky-blue m-right-10",
                                    attrs: { name: "icon-info-full" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "font-size-small c-light" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("Integration.excelDescription")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "right" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "add-pos-key m-bottom-20",
                                    attrs: {
                                      type: "button",
                                      id: "btn-integration-generate-pos-key",
                                      primary: "",
                                      size: "small",
                                      isLoading: _vm.isKeyGenerating,
                                      disabled: _vm.disabledPosKeyButton,
                                    },
                                    on: { click: _vm.generatePosKey },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Global.Save"))),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "flexbox justify-space-between" },
                          [
                            _c(
                              "div",
                              { staticClass: "left" },
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "add-pos-key m-bottom-20",
                                    attrs: {
                                      type: "button",
                                      id: "btn-integration-generate-pos-key",
                                      success: "",
                                      isLoading: _vm.isKeyGenerating,
                                      disabled: _vm.disabledPosKeyButton,
                                    },
                                    on: { click: _vm.generatePosKey },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("Integration.addPosKey"))
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                  _c("IntegrationTable", {
                    ref: "posIntegrationTable",
                    attrs: {
                      fields: _vm.integrationFields,
                      data: _vm.configList,
                      readOnly: _vm.isExcelType,
                      toggleSelectedAllIgnoreAction:
                        _vm.makeToggleSelectedIgnoreItemFunction,
                      hasAction: !_vm.isExcelType,
                    },
                    model: {
                      value: _vm.selectedIntegration,
                      callback: function ($$v) {
                        _vm.selectedIntegration = $$v
                      },
                      expression: "selectedIntegration",
                    },
                  }),
                  _c("portal", {
                    attrs: { to: "integrationRowPortal" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return _c(
                            "div",
                            { staticClass: "flexbox justify-flex-end" },
                            [
                              row.propertyKey
                                ? _c("Button", {
                                    attrs: {
                                      variant: "btn-icon action-remove",
                                      type: "button",
                                      justIcon: true,
                                      isLoading: _vm.isDeletingPosKey,
                                      disabled: _vm.isDeletingPosKey,
                                      iconName: "icon-delete",
                                      id: "btn-integration-delete-pos-key",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletePosKey(row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }