import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'

export default {
  namespaced: true,

  /**
  */

  state: {
    pageTabLinks: [
      'IntegrationB2bLogo',
      'IntegrationB2bLogoSupplier',
      'IntegrationB2bLogoStore',
      'IntegrationB2bLogoTax',
      'IntegrationB2bLogoUnit'
    ],
    selectedStoreGroup: {}
  },

  /**
  */

  getters: {
    pageTabLinks: state => state.pageTabLinks,
    selectedStoreGroup: state => state.selectedStoreGroup
  },

  /**
  */

  actions: {

    async fetchB2bSupplier({ commit }, payload) {
      const loadingName = 'fetchB2bSupplier'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/b2b/config/supplier/mapping', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async updateSupplierConfig({ commit }, payload) {
      let results = await requestHandler({
        title: 'updateSupplierConfig',
        methodName: 'updateSupplierConfig',
        method: () => {
          return HTTP.post('integration/b2b/config/supplier/mapping', payload)
        },
        success: result => result
      })

      return results
    },

    async storeCodeUpdate({ commit }, payload) {
      let results = await requestHandler({
        title: 'storeCodeUpdate',
        methodName: 'storeCodeUpdate',
        method: () => {
          return HTTP.put('store/code', payload)
        },
        success: result => result
      })

      return results
    },

    async unitCodeUpdate({ commit }, payload) {
      let results = await requestHandler({
        title: 'unitCodeUpdate',
        methodName: 'unitCodeUpdate',
        method: () => {
          return HTTP.put('unit/unitnumber', payload)
        },
        success: result => result
      })

      return results
    },

    async taxCodeUpdate({ commit }, payload) {
      let results = await requestHandler({
        title: 'unitCodeUpdate',
        methodName: 'unitCodeUpdate',
        method: () => {
          return HTTP.put('tax/code', payload)
        },
        success: result => result
      })

      return results
    }

  },

  /**
  */

  mutations: {
    SET_SELECTED_STORE_GROUP(state, storeGroup) {
      state.selectedStoreGroup = {
        ...storeGroup
      }
    },

    RESET_STORE_GROUP(state) {
      state.selectedStoreGroup = {}
    }
  }
}
