<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs.m-left-10(
      :links="pageTabLinks",
      translationGroup="SavosDigitalPlanet"
    )
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-15(
        :list="storeGroups",
        :selectedItem="selectedStoreGroup",
        @delegateChangeSelectedItem="onChangeStoreGroup",
        selectedKey="storeGroupId",
        fieldKey="storeGroupName"
      )
  template(slot="content-center")
    TableLoading(v-if="isFetchingSettingInfo || isFetchingData")
    StoreGroupConfigForm(
      v-else
      :form="form",
      :isTypeDigitalPlanet="true"
      :taxNumber="selectedStoreGroup.taxNumber"
      :isLoadingAction="isLoading"
      @onSubmitAction="onSubmitForm"
    )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import StoreGroupConfigForm from '@/view/pages/Integration/components/SettingsForm/storeGroupConfigForm'
import { vueWaitLoader } from '@/utils/baseOperations'
//

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'SovosDigitalPlanet',
  /**
  **/
  data () {
    return {
      form: {
        username: null,
        password: null,
        alias: null,
        corporateCode: null
      },
      storeGroups: [],
      selectedStore: null
    }
  },
  /**
  **/
  components: {
    ToggleOverGroups,
    StoreGroupConfigForm
  },

  computed: {
    ...mapGetters('EConversionStoreGroupConfig', ['pageTabLinks', 'selectedStoreGroup', 'storeGroupList']),
    ...mapGetters('Settings', ['UserInfo']),

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isFetchingSettingInfo () {
      return this.$wait.is(['getProtelIntegratorStoreGroups', 'configPageLoading'])
    },
    isFetchingData () {
      return this.$wait.is(['getProtelIntegratorInfo'])
    },

    isLoading () {
      return this.$wait.is(['storeGroupConfigProtelIntegrator'])
    }
  },

  methods: {
    ...mapActions('EConversionStoreGroupConfig', [
      'getProtelIntegratorStoreGroups',
      'getProtelIntegratorInfo',
      'updateProtelIntegratorSettings'
    ]),

    ...mapMutations('EConversionStoreGroupConfig', ['SET_SELECTED_STORE_GROUP']),

    getIntegrationStoreGroupWithParams () {
      const params = {
        StoreGroupId: this.selectedStoreGroup.storeGroupId,
        IntegrationProductId: this.IntegrationProductId
      }
      this.getProtelIntegratorInfo(params)
        .then(res => {
          if (res.data.eInvoiceStoreGroup) {
            this.form = res.data.eInvoiceStoreGroup
          } else this.form = {}
        })
    },

    async getStoreGroups () {
      const payload = {
        IntegrationProductId: this.$route.params.IntegrationProductId
      }
      await this.getProtelIntegratorStoreGroups(payload).then(res => {
        this.storeGroups = res.data.eInvoiceStoreGroupList
        const defaultStore = this.storeGroups[0]
        const selectedStoreGroup = this.selectedStoreGroup.hasOwnProperty('storeGroupId') ? this.selectedStoreGroup : defaultStore
        this.onChangeStoreGroup(selectedStoreGroup)
      })
    },

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async onChangeStoreGroup (storeGroup) {
      this.SET_SELECTED_STORE_GROUP(storeGroup)
      await this.getIntegrationStoreGroupWithParams()
    },

    resetForm () {
      this.form.username = null
      this.form.alias = null
      this.form.password = null
    },

    onSubmitForm: vueWaitLoader(async function (form) {
      if (this.form.username === '') this.resetForm()
      if (this.form.alias === '') this.form.alias = null
      if (this.form.password === '') this.form.password = null

      let params = {
        ...form,
        isPasswordFilled: !!form.password,
        integrationProductId: this.IntegrationProductId,
        storeGroupId: this.selectedStoreGroup.storeGroupId
      }
      const res = await this.updateProtelIntegratorSettings(params)
      if (!res) return
      const message = this.$t('Integration.StoreGroupSuccessUpdated')
      this.notifyShow({ message })
      this.getIntegrationStoreGroupWithParams()
    }, 'storeGroupConfigProtelIntegrator')
  },

  async mounted () {
    this.$wait.start('configPageLoading')
    await this.getStoreGroups()
    this.$wait.end('configPageLoading')
  }
}
</script>

<style lang="scss">

  .checkField {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $input-size;
    margin-top: 27.5px; // for label and lineheight
    margin-bottom: 48px;

  }

</style>
