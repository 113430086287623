<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs(
      :links="pageTabLinks",
      translationGroup="WarehouseManagement"
    )
  template(slot="overgroups-left")
    ToggleOverGroups(
      v-if="!isFetchingPageData"
      :list="intWmsStoreGroupList",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      selectedKey="storeGroupId",
      fieldKey="storeGroupName"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingPageData")
    IntegrationTable(
      v-else
      :fields="integrationFields",
      @onChange="updateItem"
      :data="storeList"
      :isLoadingAction="isLoading"
    )

</template>

<script>
import IntegrationTable from '@/view/pages/Integration/components/Table'
import { vueWaitLoader } from '@/utils/baseOperations'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
//
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WMAxataStore',
  components: {
    IntegrationTable,
    ToggleOverGroups
  },

  data () {
    return {
      intWmsStoreGroupList: [],
      storeList: [],
      selectedStoreGroup: null
    }
  },

  computed: {
    ...mapGetters('IntegrationWarehouseManagementAxata', ['pageTabLinks']),

    isFetchingPageData () {
      return this.$wait.is(['fetchWMSConfigStoreGroupAll', 'fetchWMSConfigStore'])
    },
    isLoading () {
      return this.$wait.is('storeCodeUpdateWMSAxata')
    },
    integrationFields () {
      return [
        {
          name: 'storeName',
          text: this.$t('Dictionary.Store')
        },
        {
          name: 'code',
          isEditable: true,
          text: this.$t('Dictionary.AccountCode'),
          input: {
            validateRule: 'max:32'
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('IntegrationWarehouseManagementAxata', ['fetchWMSConfigStoreGroupAll', 'fetchWMSConfigStore', 'updateWMSConfigStore']),

    async fetchStoreGroupList () {
      const result = await this.fetchWMSConfigStoreGroupAll({
        IntegrationProductId: this.$route.params.IntegrationProductId
      })
      this.intWmsStoreGroupList = result.data.intWmsStoreGroupList.filter(storeGroup => storeGroup.id)

      if (this.intWmsStoreGroupList.length > 0) this.selectedStoreGroup = this.intWmsStoreGroupList[0]
    },

    async fetchStoreList () {
      this.fetchWMSConfigStore({IntWmsStoreGroupId: this.selectedStoreGroup.id})
        .then(res => {
          if (!res) return
          this.storeList = res.data.intWmsStoreList
        })
    },

    async onChangeStoreGroup (storeGroup) {
      this.selectedStoreGroup = storeGroup
      await this.fetchStoreList()
    },

    updateItem: vueWaitLoader(async function (store) {
      const payload = {
        intWmsStoreGroupId: this.selectedStoreGroup.id,
        storeId: store.storeId,
        code: store.code
      }
      const res = await this.updateWMSConfigStore(payload)
      if (res) {
        const message = this.$t('Integration.StoreSuccessUpdated', { name: store.storeName })
        this.notifyShow({ message })
      }
    }, 'storeCodeUpdateWMSAxata')
  },

  async beforeMount () {
    await this.fetchStoreGroupList()
    await this.fetchStoreList()
  }
}
</script>
