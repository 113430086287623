var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gelir-aktarimi" },
    [
      _c(
        "Button",
        {
          attrs: {
            size: "small",
            variant: "border-gray c-primary",
            disabled: _vm.isLoadingImportMenuItems,
            id: "btn-gelir-aktarimi",
            type: "button",
          },
          on: { click: _vm.onClickButton },
        },
        [
          _vm.isLoadingImportMenuItems
            ? _c("Loading", { attrs: { theme: "disable" } })
            : _vm._e(),
          _c("Icon", { attrs: { name: "icon-postan-aktar" } }),
          _vm._v(_vm._s(_vm.$t("MenuItems.salesUploadFile"))),
        ],
        1
      ),
      _c("input", {
        ref: "fileInput",
        attrs: { type: "file", disabled: _vm.isLoading },
        on: {
          change: function ($event) {
            return _vm.processFile($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }