import Extensions from '../index'
// Accounting
import Accounting from '../Accounting/Logo/index'
import AccountingStore from '../Accounting/Logo/store'
import AccountingTax from '../Accounting/Logo/tax'
import AccountingSupplier from '../Accounting/Logo/supplier'
import AccountingGroup from '../Accounting/Logo/group'
import AccountingWasteGroup from '../Accounting/Logo/waste-group'

import Xero from '../Accounting/Xero/index'
import XeroTax from '../Accounting/Xero/tax'
import XeroSupplier from '../Accounting/Xero/supplier'
import XeroStore from '../Accounting/Xero/store'
import XeroGroup from '../Accounting/Xero/group'
import XeroWasteGroup from '../Accounting/Xero/waste-group'
import XeroAuthorization from '../Accounting/Xero/authorization'

// Pos
import SimpraPos from '../Pos/Simpra/index'
import GenericPos from '../Pos/GenericPos/index'

// B2B
import B2B from '../B2b/B2b/index'
import B2BConfigForm from '../B2b/B2b/popup/b2bConfigForm'
import B2BConfigRemove from '../B2b/B2b/popup/removeB2bConfig'
import B2B2Config from '../B2b/B2b/data-config'
import B2BLogo from '../B2b/Logo/storeGroupConfig'
import B2BLogoStore from '../B2b/Logo/store.vue'
import B2BLogoSupplier from '../B2b/Logo/supplier'
import B2BLogoTaxConfig from '../B2b/Logo/tax'
import B2BLogoUnitConfig from '../B2b/Logo/unit'

// MASSEC
import Massec from '../Massec/index'

// EMKO
import Emko from '../EMKO/index'

// Warehouse Management
// Axata 

import Axata from '../WarehouseManagement/Axata/storeGroupConfig'
import AxataStore from '../WarehouseManagement/Axata/store.vue'
import AxataSupplier from '../WarehouseManagement/Axata/supplier'


// EConversion
import ProtelIntegrator from '../eConversion/protelIntegrator'
import ELogo from '../eConversion/eLogo'
import EFinans from '../eConversion/eFinans'
import DigitalPlanet from '../eConversion/digital-planet'
import iBar from '../iBar'
import PortalPlus from '../eConversion/portal-plus'
import Dia from '../eConversion/dia'

//SalesForecasting
import SalesForecasting from '../SalesForecasting/index'

const accountingBreadcrumb = [
  {
    name: 'Extensions',
    to: 'Extensions'
  },
  {
    name: 'accounting',
    to: null
  }
]

export default [
  {
    path: '/extensions',
    name: 'Extensions',
    component: Extensions,
    meta: {
      main_menu: true,
      slug: 'extensions',
      category: 'Governance',
      permissionKey: 'IntegrationMenu',
      page: 'extensions',
      pageTitleColor: 'primary'
    }
  },
  /*
  *
  *
  Accounting
  *
  */
  {
    name: 'IntegrationAccountingLogo',
    path: '/extensions/accountinglogo/:IntegrationProductId',
    component: Accounting,
    meta: {
      page: 'integrationAccountingLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingLogoStore',
    path: '/extensions/accountinglogo/:IntegrationProductId/store',
    component: AccountingStore,
    meta: {
      page: 'integrationAccountingLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingLogoTax',
    path: '/extensions/accountinglogo/:IntegrationProductId/tax',
    component: AccountingTax,
    meta: {
      page: 'integrationAccountingLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingLogoSupplier',
    path: '/extensions/accountinglogo/:IntegrationProductId/supplier',
    component: AccountingSupplier,
    meta: {
      page: 'integrationAccountingLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingLogoItemGroup',
    path: '/extensions/accountinglogo/:IntegrationProductId/group',
    component: AccountingGroup,
    meta: {
      page: 'integrationAccountingLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingLogoWasteGroup',
    path: '/extensions/accounting/logo/:IntegrationProductId/waste-group',
    component: AccountingWasteGroup,
    meta: {
      page: 'integrationAccountingLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },

  {
    name: 'IntegrationAccountingXero',
    path: '/extensions/accounting/xero/:IntegrationProductId',
    component: Xero,
    meta: {
      page: 'integrationXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingXeroStore',
    path: '/extensions/accounting/xero/:IntegrationProductId/store',
    component: XeroStore,
    meta: {
      page: 'integrationAccountingXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingXeroTax',
    path: '/extensions/accounting/xero/:IntegrationProductId/tax',
    component: XeroTax,
    meta: {
      page: 'integrationAccountingXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingXeroSupplier',
    path: '/extensions/accounting/xero/:IntegrationProductId/supplier',
    component: XeroSupplier,
    meta: {
      page: 'integrationAccountingXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingXeroItemGroup',
    path: '/extensions/accounting/xero/:IntegrationProductId/group',
    component: XeroGroup,
    meta: {
      page: 'integrationAccountingXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingXeroWasteGroup',
    path: '/extensions/accounting/xero/:IntegrationProductId/waste-group',
    component: XeroWasteGroup,
    meta: {
      page: 'integrationAccountingXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },
  {
    name: 'IntegrationAccountingXeroAuthorization',
    path: '/accounting/xero/authorization',
    component: XeroAuthorization,
    meta: {
      page: 'integrationAccountingXero',
      pageTitleColor: 'primary',
      breadcrumb: [
        ...accountingBreadcrumb
      ]
    }
  },

  /*
   *
   *
   POS
   *
   */
  {
    name: 'IntegrationPosSimpra',
    path: '/extensions/pos/simpra/:IntegrationProductId',
    component: SimpraPos,
    meta: {
      page: 'Simpra Pos',
      posName: 'Simpra Pos',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'pos',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationPosSimphony',
    path: '/extensions/pos/simphony/:IntegrationProductId',
    component: GenericPos,
    meta: {
      posName: 'Simphony',
      page: 'Simphony',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'pos',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationPos3700',
    path: '/extensions/pos/3700/:IntegrationProductId',
    component: GenericPos,
    meta: {
      page: '3700',
      posName: '3700',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'pos',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationPosExcel',
    path: '/extensions/pos/excel/:IntegrationProductId',
    component: GenericPos,
    meta: {
      page: 'Excel',
      posName: 'Excel',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'pos',
          to: null
        }
      ]
    }
  },
   /*
   *
   *
   B2B
   *
   */
  {
    name: 'IntegrationB2bLogo',
    path: '/extensions/b2b/logo/:IntegrationProductId',
    component: B2BLogo,
    meta: {
      page: 'LOGO (B2B)',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bLogo',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationB2bLogoStore',
    path: '/extensions/b2b/logo/:IntegrationProductId/store',
    component: B2BLogoStore,
    meta: {
      pageTitleColor: 'primary',
      page: 'LOGO (B2B)',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bLogo',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationB2bLogoSupplier',
    path: '/extensions/b2b/logo/:IntegrationProductId/supplier',
    component: B2BLogoSupplier,
    meta: {
      page: 'LOGO (B2B)',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bLogo',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationB2bLogoTax',
    path: '/extensions/b2b/logo/:IntegrationProductId/tax',
    component: B2BLogoTaxConfig,
    meta: {
      page: 'LOGO (B2B)',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bLogo',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationB2bLogoUnit',
    path: '/extensions/b2b/logo/:IntegrationProductId/unit',
    component: B2BLogoUnitConfig,
    meta: {
      page: 'LOGO (B2B)',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bLogo',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationB2bB2B',
    path: '/extensions/b2b/b2b/:IntegrationProductId',
    component: B2B,
    meta: {
      page: 'B2B',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bB2B',
          to: null
        }
      ]
    },

    children: [
      {
        name: 'newB2bConfig',
        path: '/extensions/b2b/b2b/definition/:IntegrationProductId/new',
        component: B2BConfigForm
      },
      {
        name: 'editB2bConfig',
        path: '/extensions/b2b/b2b/definition/:IntegrationProductId/edit/:id',
        component: B2BConfigForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'deleteB2bConfig',
        path: '/extensions/b2b/b2b/definition/:IntegrationProductId/delete/:id',
        component: B2BConfigRemove
      }
    ]
  },
  {
    name: 'IntegrationB2bB2BDataConfig',
    path: '/extensions/b2b/b2b/:IntegrationProductId/data-config/:id',
    component: B2B2Config,
    meta: {
      page: 'B2B',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationB2bB2B',
          to: 'IntegrationB2bB2B'
        }
      ]
    }
  },
  /**
  * Massec
  */
  {
    name: 'IntegrationOtherMassec',
    path: '/extensions/other/massec/:IntegrationProductId',
    component: Massec,
    meta: {
      page: 'Massec',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationOtherMassec',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationOtherEMKO',
    path: '/extensions/other/emko/:IntegrationProductId',
    component: Emko,
    meta: {
      page: 'Emko',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationOtherEMKO',
          to: null
        }
      ]
    }
  },
  {
    name: 'SalesForecasting',
    path: '/extensions/other/sales-forecasting/:IntegrationProductId',
    component: SalesForecasting,
    meta: {
      page: 'SalesForecasting',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'SalesForecasting',
          to: null
        }
      ]
    }
  },
  {
    name: 'ProtelIntegratorStoreGroupConfig',
    path: '/extensions/e-conversion/protel-integrator/:IntegrationProductId',
    component: ProtelIntegrator,
    meta: {
      page: 'Protel Integrator',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'ProtelIntegratorStoreGroupConfig',
          to: null
        },
        {
          name: 'StoreGroupConfig',
          to: null
        }
      ]
    }
  },
  {
    name: 'SovosDigitalPlanet',
    path: '/extensions/e-conversion/sovos-digital-planet/:IntegrationProductId',
    component: DigitalPlanet,
    meta: {
      page: 'Sovos Digital Planet',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'SovosDigitalPlanet',
          to: null
        },
        {
          name: 'StoreGroupConfig',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationProtelPortalPlusE-Despatch',
    path: '/extensions/e-conversion/portal-plus/:IntegrationProductId',
    component: PortalPlus,
    meta: {
      page: 'Portal Plus',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'PortalPlus',
          to: null
        },
        {
          name: 'StoreGroupConfig',
          to: null
        }
      ]
    }
  },
  {
    name: 'IntegrationProtelDIA',
    path: '/extensions/e-conversion/dia/:IntegrationProductId',
    component: Dia,
    meta: {
      page: 'Dia',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'Dia',
          to: null
        },
        {
          name: 'StoreGroupConfig',
          to: null
        }
      ]
    }
  },


  /**
  * Warehouse Management
  */

  {
    name: 'WarehouseManagementAxata',
    path: '/extensions/warehouse-management/axata/:IntegrationProductId',
    component: Axata,
    meta: {
      page: 'Axata',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationWMAxata',
          to: null
        },
      ]
    }
  },
  {
    name: 'WarehouseManagementAxataStore',
    path: '/extensions/warehouse-management/axata/:IntegrationProductId/store',
    component: AxataStore,
    meta: {
      page: 'Axata',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationWMAxata',
          to: null
        },
      ]
    }
  },
  {
    name: 'WarehouseManagementAxataSupplier',
    path: '/extensions/warehouse-management/axata/:IntegrationProductId/supplier',
    component: AxataSupplier,
    meta: {
      page: 'Axata',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationWMAxata',
          to: null
        },
      ]
    }
  },
  {
    name: 'IntegrationOtheriBar',
    path: '/extensions/other/ibar/:IntegrationProductId',
    component: iBar,
    meta: {
      page: 'iBar',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'iBar',
          to: null
        }
      ]
    }
  },

  {
    name: 'IntegrationProtelE-Logo',
    path: '/extensions/e-conversion/e-logo/:IntegrationProductId',
    component: ELogo,
    meta: {
      page: 'eLogo',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationProtelE-Logo',
          to: null
        },
        {
          name: 'StoreGroupConfig',
          to: null
        }
      ]
    }
  },

  {
    name: 'IntegrationProtelEFinans',
    path: '/extensions/e-conversion/qnb-e-finans/:IntegrationProductId',
    component: EFinans,
    meta: {
      page: 'eFinans',
      pageTitleColor: 'primary',
      breadcrumb: [
        {
          name: 'Extensions',
          to: 'Extensions'
        },
        {
          name: 'IntegrationProtelEFinans',
          to: null
        },
        {
          name: 'StoreGroupConfig',
          to: null
        }
      ]
    }
  }
]
