import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    configList: []
  },

  getters: {
    configList: state => state.configList
  },

  actions: {
    fetchShelfStorageRefigeratorList({ commit }, params) {
      const loadingName = 'fetchShelfStorageRefigeratorList'
      const results = requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/refrigerator/config/all', { params: { ...params } })
        },
        success: result => {
          commit('SET_REFIGERATOR_LIST', result.data)
          return result
        }
      })
      return results
    },

    async saveIntegrationEmkoRefigeratorPropertyKey({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntegrationEmkoRefigeratorPropertyKey',
        methodName: 'saveIntegrationEmkoRefigeratorPropertyKey',
        method: () => {
          return HTTP.post('integration/refrigerator/config/save', payload)
        },
        success: result => result
      })
      return results
    },
  },

  mutations: {
    SET_REFIGERATOR_LIST(state, data) {
      state.configList = data.intRefrigeratorList
    },
    RESET_REFIGERATOR_LIST(state) {
      state.configList = []
    }
  }

}