<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs(
      :links="pageTabLinks",
      translationGroup="WarehouseManagement"
    )
  template(slot="overgroups-left")
    ToggleOverGroups(
      :list="intWmsStoreGroupList",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      selectedKey="storeGroupId",
      fieldKey="storeGroupName"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingSupplier")
    IntegrationTable(
      v-else,
      :fields="fields",
      :data="supplierList",
      @onChange="updateItem"
      :isLoadingAction="isLoading"
    )
</template>

<script>
import IntegrationTable from '@/view/pages/Integration/components/Table'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
//
import alertbox from '@/mixins/alertbox'
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'WarehouseManagementAxataSupplier',

  mixins: [
    alertbox()
  ],

  components: {
    IntegrationTable,
    ToggleOverGroups
  },

  data () {
    return {
      search: null,
      supplierList: [],
      selectedStoreGroup: {},
      intWmsStoreGroupList: []
    }
  },

  computed: {
    ...mapGetters('IntegrationWarehouseManagementAxata', ['pageTabLinks']),
    // ...mapGetters('IntegrationAccountingLogo', ['intWmsStoreGroupList']),

    isFetchingSupplier () {
      return this.$wait.is(['fetchWMSConfigSupplier', 'pageLoading'])
    },

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isLoading () {
      return this.$wait.is(['saveIntSupplierWMSAxata'])
    },

    fields () {
      return [
        {
          name: 'supplierName',
          text: this.$t('Integration.Supplier_InputField_Supplier_Name')
        },
        { name: 'code',
          isEditable: true,
          text: this.$t('Integration.Supplier_InputField_Supplier_AccountCode'),
          input: {
            validateRule: 'max:32'
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('IntegrationWarehouseManagementAxata', [
      'fetchWMSConfigStoreGroupAll',
      'fetchWMSConfigSupplier',
      'updateWMSConfigSupplier'
    ]),

    updateItem: vueWaitLoader(async function (row) {
      const payload = {
        IntWmsStoreGroupId: this.selectedStoreGroup.id,
        supplierId: row.supplierId,
        code: row.code
      }

      await this.updateWMSConfigSupplier(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('Integration.StoreSuccessUpdated', { name: row.supplierName })
          this.notifyShow({ message })
        })
    }, 'saveIntSupplierWMSAxata'),

    onChangeStoreGroup (storeGroup) {
      if (storeGroup === this.selectedStoreGroup) return
      this.selectedStoreGroup = storeGroup
      this.getSupplierList()
    },

    async getSupplierList () {
      this.fetchWMSConfigSupplier({
        IntWmsStoreGroupId: this.selectedStoreGroup.id
      })
        .then(res => {
          this.supplierList = res.data.intWmsSupplierList
        })
    },

    makeToggleSelectedIgnoreItemFunction (item) {
      return !item.isEnabled
    }
  },

  async mounted () {
    this.$wait.start('pageLoading')
    const result = await this.fetchWMSConfigStoreGroupAll({
      IntegrationProductId: this.IntegrationProductId
    })
    this.intWmsStoreGroupList = result.data.intWmsStoreGroupList.filter(storeGroup => !!storeGroup.id)
    // const defaultStore = this.intWmsStoreGroupList.find(_ => _.isStoreGroupDefault)
    // const selectedStoreGroup = this.selectedStoreGroup.hasOwnProperty('storeGroupId') ? this.selectedStoreGroup : defaultStore
    if (this.intWmsStoreGroupList.length > 0) {
      await this.onChangeStoreGroup(this.intWmsStoreGroupList[0])
    }
    this.$wait.end('pageLoading')
  }
}
</script>
