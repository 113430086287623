<template lang="pug">
.IntegrationTable
    .custom-table(:class="{ 'hasAction': hasAction }")
      .row.header
        .col(
          v-for="(field, index) in fields",
          :key="`integration-table-row-col-header-${index}`",
          :id="`integration-table-row-col-header-${index}`"
        )
          template(v-if="field.hasCheckbox")
            CustomCheckbox(
            :id="`integration-table-row-all-selected-checkbox`"
            :label="field.text",
            :checked="isAllSelected"
            @change="isChecked => toggleAllSelected(isChecked)")

          template(v-else)
            span {{ field.text }}

      .row(
        v-for="(row, rowIndex) in data",
        :key="`integration-table-row-${rowIndex}`",
      )
        .col(
          v-for="(field, index) in fields",
          :key="`integration-table-row-col-${rowIndex}-${index}`",
          :id="`integration-table-row-col-${rowIndex}-${index}`"
        )
          template(v-if="field.hasCheckbox")
            CustomCheckbox(
            :id="`integration-table-row-${rowIndex}-checkbox`"
            :label="row[field.name]",
            :disabled="field.checkboxDisabled ? field.checkboxDisabled(row) : false",
            :checked="!!selectedItems.find(item => item[field.uniqueKey] === row[field.uniqueKey])"
            @change="isChecked => onItemSelect(isChecked,row)")

          template(v-else-if="(field.isEditable) || field.readonlyValue")
            .flexbox.align-center(v-if="field.isEditable")
              input.txt(
                :name="`input-integration-${field.name}-${rowIndex}`",
                autocomplete="off",
                :data-vv-as="field.text",
                :ref="`${field.name}_${rowIndex}`",
                v-model="form[rowIndex][field.name]",
                :id="`input-integration-${field.name}-${rowIndex}`",
                :class="{ 'is-danger': veeErrors.has(`input-integration-${field.name}-${rowIndex}`) }"
                v-validate="validateRules(field, row)",
                @keypress.self.prevent.enter="onFocusInput(field.name, rowIndex)",
                @change=" isFocusable ? onChangeValue(field.name, row, rowIndex) : null"
              )

              Button.m-left-10(
                primary
                size="small"
                tabindex="-1",
                :id="`button-integration-${field.name}-${rowIndex}`"
                variant="icon",
                :disabled="veeErrors.has(`input-integration-${field.name}-${rowIndex}`) || ( !nullable && !(!!form[rowIndex][field.name])) || isLoading"
                iconName="icon-btn-success",
                @click="onChangeValue(field.name, row, rowIndex)"
                :justIcon="true"
                iconClass="icon-btn-success"
              )



            .flexbox(v-if="field.readonlyValue")
              input.txt(
                :value="row[field.name]",
                :placeholder="field.emptyText",
                :disabled="true",
                :readonly="true"
              )
          template(v-else)
            span {{ row[field.name] }}

        .col
          portalTarget(
            name="integrationRowPortal"
            :slot-props="row"
          )
</template>

<script>


export default {
  name: 'IntegrationTable',

  props: {
    value: {},
    fields: {
      type: Array,
      default: () => []
    },
    isFocusable: {
      type: Boolean,
      default: true
    },
    hasAction: {
      type: Boolean,
      default: false
    },
    nullable: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: () => []
    },
    toggleSelectedAllIgnoreAction: {
      type: Function,
      default: null
    },
    isLoadingAction: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: [],
      selectedItems: []
    }
  },

  computed: {
    isValid () {
      return this.veeErrors.items.length === 0
    },
    validateRules () {
      return (field, row) => {
        if (field.input.validateSingularityParams) {
          const validateSingularityParams = field.input.validateSingularityParams
          return field.input.validateRule.replace(`${validateSingularityParams}=`, `${validateSingularityParams}=${row[validateSingularityParams]}`)
        } else {
          return field.input.validateRule
        }
      }
    },

    isAllSelected () {
      return this.selectedItems.length === this.data.length
    },

    isLoading () {
      return this.isLoadingAction
    }
  },

  methods: {

    async makeForms () {
      if (this.data.length === 0) return
      let form = []
      this.data.forEach(row => {
        let rowData = {}
        this.fields.filter(field => field.isEditable).forEach(item => {
          rowData[item.name] = row[item.name]
        });
        form.push(rowData)
      });
      this.form = [...form]
    },

    async onChangeValue (field, row, rowIndex) {
      if (!this.isValid) return
      const newData = {
        ...row,
        ...this.form[rowIndex]
      }
      this.$emit('onChange', newData)
    },

    onFocusInput (field, index) {
      if (!this.isFocusable) return
      const focusableIndex = this.data.length - 1 === index ? 0 : index + 1
      const key = `${field}_${focusableIndex}`
      if (this.data.length === 1) {
        this.$refs[key][0].blur()
      } else {
        this.$refs[key][0].focus()
      }
    },

    onItemSelect (isChecked, row) {
      if (isChecked) {
        this.selectedItems.push(row)
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(row), 1)
      }
      this.$emit('input', this.selectedItems)
      this.$emit('checkboxChangeAction', {
        isChecked,
        row
      })
    },

    toggleAllSelected (isChecked) {
      if (isChecked) {
        this.selectedItems = [...this.data]
      } else {
        this.selectedItems = this.toggleSelectedAllIgnoreAction ? [...this.data.filter(item => !this.toggleSelectedAllIgnoreAction(item))] : []
      }
      this.$emit('input', this.selectedItems)
    },

    setSelectedItems () {
      if (this.fields.find(item => item.hasCheckbox)) {
        this.selectedItems = [...this.value]
      }
    }
  },

  async mounted () {
    await this.setSelectedItems()
    this.$watch('data', async () => {
      await this.makeForms()
      await this.setSelectedItems()
    })
  }
}
</script>

<style scoped lang="scss">

  .custom-table {
    background: $color-white;
    &.hasAction {
      .row {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .row {
      grid-template-columns: repeat(5, 1fr);
      &:nth-child(2n-1):not(.header){
        background: $color-light-gray;
      }
      label {
        display: inline-block;
      }
      .txt {
        max-width: 450px;
        min-height: 44px;
      }
    }
  }
</style>
