var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            attrs: { links: _vm.pageTabLinks, translationGroup: "B2B" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _c("IntegrationTable", {
            attrs: {
              fields: _vm.integrationFields,
              data: _vm.UnitList,
              isLoadingAction: _vm.isLoading,
            },
            on: { onChange: _vm.updateItem },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }