<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    div
  template(slot="content-center")
    .settings
      .settings-item
        .settings-header
          label.settings-label {{ $t('Integration.Settings_Emko') }}
        .settings-content
          TableLoading(v-if="isFetchingPageData")
          IntegrationTable(
            v-else
            :isFocusable="false",
            @onChange="updateItem",
            :fields="integrationFields",
            :data="configList"
            :isLoadingAction="isLoading"
          )
</template>

<script>
import IntegrationTable from '@/view/pages/Integration/EMKO/components'

import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'SimpraEmkoIntegration',
  /*
  */
  components: {
    IntegrationTable,
  },
  /*
  */
  data () {
    return {
      IntegrationProductId: null,
      integrationFields: [
        {
          name: 'shelfStorageLocationListHeaderName',
          text: this.$t('Integration.Emko_shelfStorageLocation')
        },
        {
          name: 'currentAlarmAccessToken',
          isEditable: true,
          text: this.$t('Integration.Emko_currentAlarmAccessToken'),
          input: {
          }
        },
        { name: 'currentDataAccessToken',
          isEditable: true,
          text: this.$t('Integration.Emko_currentDataAccessToken'),
          input: {
          }
        },
        {
          name: 'historicalAlarmAccessToken',
          isEditable: true,
          text: this.$t('Integration.Emko_historicalAlarmAccessToken'),
          input: {
          }
        },
        { name: 'historicalDataAccessToken',
          isEditable: true,
          text: this.$t('Integration.Emko_historicalDataAccessToken'),
          input: {
          }
        }
      ]
    }
  },

  computed: {
    isFetchingPageData () {
      return this.$wait.is('fetchShelfStorageRefigeratorList')
    },
    isLoading () {
      return this.$wait.is(['saveIntegrationSimprapropertyKeyItem'])
    },
    ...mapGetters('IntegrationOtherEMKO', ['configList'])

  },

  methods: {

    ...mapActions('IntegrationOtherEMKO', [
      'fetchShelfStorageRefigeratorList',
      'saveIntegrationEmkoRefigeratorPropertyKey',
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    updateItem: vueWaitLoader(async function (row) {
      const payload = {
        integrationProductId: this.IntegrationProductId,
        shelfStorageLocationListHeaderId: row.shelfStorageLocationListHeaderId,
        currentDataAccessToken: row.currentDataAccessToken,
        historicalDataAccessToken: row.historicalDataAccessToken,
        currentAlarmAccessToken: row.currentAlarmAccessToken,
        historicalAlarmAccessToken: row.historicalAlarmAccessToken
      }
      const res = await this.saveIntegrationEmkoRefigeratorPropertyKey(payload)
      if (res) {
        const message = this.$t('Integration.TaxSuccessUpdated', { name: row.shelfStorageLocationListHeaderName })
        this.notifyShow({ message })
      }
    }, 'saveIntegrationEmkoRefigeratorPropertyKey')
  },

  async mounted () {
    this.IntegrationProductId = this.$route.params.IntegrationProductId
    await this.fetchShelfStorageRefigeratorList({
      IntegrationProductId: this.IntegrationProductId
    })
  },
}
</script>

<style lang="scss" scoped>

.settings {

  &-header {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    font-size: $font-size-big;
    color: $color-dark;
    opacity: 0.46;
  }

  &-content.sync {
    display: flex;

    button {
      margin-left: 15px;
      width: max-content;
    }
  }

  &-item:not(:first-child) {
    margin-top: 30px;
  }

  &-label {

    &:after {
      content: "";
      position: absolute;
      display: inline-block;
      color: $color-dark;
      opacity: 0.46;
      width: 100%;
      height: 1px;
      background-color: black;
      margin-left: 10px;
      top: 50%;
    }
  }
}
</style>
