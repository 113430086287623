var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingData || _vm.isUpdatingForm || _vm.isCheckingForm
            ? _c("TableLoading")
            : _c("StoreGroupConfigForm", {
                attrs: {
                  form: _vm.form,
                  useAlias: false,
                  isLoadingAction: _vm.isFetchingData,
                  disableSubmit:
                    (this.iBarInfo &&
                      _vm.form.username === _vm.iBarInfo.userName &&
                      _vm.form.password === _vm.iBarInfo.password) ||
                    _vm.isUpdatingForm ||
                    _vm.isCheckingForm,
                },
                on: { onSubmitAction: _vm.onSubmitForm },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }