<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs(
      :links="pageTabLinks",
      translationGroup="B2B"
    )
  template(slot="content-center")

    IntegrationTable(
      :fields="integrationFields",
      @onChange="updateItem"
      :data="UnitList"
      :isLoadingAction="isLoading"
    )
</template>

<script>
import IntegrationTable from '@/view/pages/Integration/components/Table'
import { vueWaitLoader } from '@/utils/baseOperations'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'B2BLOGOUnit',

  components: {
    IntegrationTable
  },

  computed: {
    ...mapGetters('IntegrationB2bLogo', ['pageTabLinks']),
    ...mapGetters('Units', ['UnitList']),


    isLoading () {
      return this.$wait.is('unitCodeUpdateB2B')
    },

    integrationFields () {
      return [
        {
          name: 'name',
          text: this.$t('Dictionary.Unit')
        },
        { name: 'unitNumber',
          isEditable: true,
          text: this.$t('Dictionary.AccountCode'),
          input: {
            validateRule: 'max:32|very_singularity:unit/check/unitnumber?id=,unitNumber',
            validateSingularityParams: 'id'
          }
        }
      ]
    }
  },

  methods: {
    ...mapActions('IntegrationB2bLogo', ['unitCodeUpdate']),
    ...mapActions('Notify', ['notifyShow']),

    updateItem: vueWaitLoader(async function (unit) {
      const payload = {
        id: unit.id,
        unitnumber: unit.unitNumber
      }
      const res = await this.unitCodeUpdate(payload)
      if (res) {
        const message = this.$t('Integration.StoreSuccessUpdated', { name: unit.name })
        this.notifyShow({ message })
      }
    }, 'unitCodeUpdateB2B')
  }
}
</script>
