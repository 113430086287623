import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    B2bDefinitionList: []
  },
  getters: {
    B2bDefinitionList: state => state.B2bDefinitionList
  },
  actions: {

    async createB2bIntegration({ commit }, payload) {
      const results = await requestHandler({
        title: 'createB2bIntegration',
        methodName: 'createB2bIntegration',
        method: () => {
          return HTTP.post('integration/b2b/config/definition', payload)
        },
        success: result => result
      })
      return results
    },

    async updateB2bIntegration({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateB2bIntegration',
        methodName: 'updateB2bIntegration',
        method: () => {
          return HTTP.put('integration/b2b/config/definition', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteB2bDefinition({ commit }, Id) {
      const loadingName = 'deleteB2bDefinition'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('integration/b2b/config/definition', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async searchSupplierForB2bIntegration({ commit }, Name) {
      const loadingName = 'searchSupplierForB2bIntegration'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/b2b/config/supplier/filter', {
            params: { Name }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchingB2bDefinitionList({ commit }, payload) {
      const loadingName = 'fetchingB2bDefinitionList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/b2b/config/definition/all', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_B2B_DEFINITION_LIST', result.data)
          return result
        }
      })
      return results
    },

    async fetchingB2bDefinitionInfo({ commit }, Id) {
      const loadingName = 'fetchingB2bDefinitionInfo'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/b2b/config/definition', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchingB2bDefinitionStoreGroups({ commit }, IntB2BDefinitionId) {
      const loadingName = 'fetchingB2bDefinitionStoreGroups'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/b2b/config/storegroup', {
            params: { IntB2BDefinitionId }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchingB2bDefinitionConfigList({ commit }, payload) {
      const url = `integration/b2b/config/${payload.actionType}/all`
      const loadingName = 'fetchingB2bDefinitionConfigList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(url, {
            params: { ...payload.params }
          })
        },
        success: result => result
      })
      return results
    },

    async saveB2bDefinitionConfigList({ commit }, payload) {
      const url = `integration/b2b/config/${payload.actionType}/save`
      const loadingName = 'saveB2bDefinitionConfigList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post(url, payload.params)
        },
        success: result => result
      })
      return results
    }

  },

  mutations: {
    SET_B2B_DEFINITION_LIST(state, data) {
      state.B2bDefinitionList = data.intB2BDefinitionList.map(item => {
        return {
          ...item,
          supplierNames: item.supplierList.map(supplier => supplier.name).join(',')
        }
      })
    }
  }
}
