<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs(
      :links="pageTabLinks",
      translationGroup="B2B"
    )
  template(slot="overgroups-left")
    ToggleOverGroups(
      v-if="!isFetchingPageData"
      :list="intStoreGroupList",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      selectedKey="storeGroupId",
      fieldKey="storeGroupName"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingPageData")
    IntegrationTable(
      v-else
      :fields="integrationFields",
      @onChange="updateItem"
      :data="storeList"
      :isLoadingAction="isLoading"
    )

</template>

<script>
import IntegrationTable from '@/view/pages/Integration/components/Table'
import { vueWaitLoader } from '@/utils/baseOperations'
import ToggleOverGroups from '@/view/global/toggle-over-groups'
//
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'B2BLOGOStore',
  components: {
    IntegrationTable,
    ToggleOverGroups
  },

  data () {
    return {
      intStoreGroupList: [],
      storeList: [],
      selectedStoreGroup: null
    }
  },

  computed: {
    ...mapGetters('IntegrationB2bLogo', ['pageTabLinks']),

    isFetchingPageData () {
      return this.$wait.is(['getIntStoreGroups', 'getIntStoreList'])
    },
    isLoading () {
      return this.$wait.is('storeCodeUpdateB2B')
    },
    integrationFields () {
      return [
        {
          name: 'storeName',
          text: this.$t('Dictionary.Store')
        },
        {
          name: 'accountCode',
          isEditable: true,
          text: this.$t('Dictionary.AccountCode'),
          input: {
            validateRule: 'max:32'
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('IntegrationAccountingLogo', ['fetchIntStoreGroupList', 'getIntStoreList', 'saveIntStore']),

    async fetchStoreGroupList () {
      const result = await this.fetchIntStoreGroupList({
        IntegrationProductId: this.$route.params.IntegrationProductId
      })
      this.intStoreGroupList = result.data.intStoreGroupList.filter(storeGroup => storeGroup.id)

      if (this.intStoreGroupList.length > 0) this.selectedStoreGroup = this.intStoreGroupList[0]
    },

    async fetchStoreList () {
      this.getIntStoreList({IntStoreGroupId: this.selectedStoreGroup.id})
        .then(res => {
          if (!res) return
          this.storeList = res.data.intStoreList
        })
    },

    async onChangeStoreGroup (storeGroup) {
      this.selectedStoreGroup = storeGroup
      await this.fetchStoreList()
    },

    updateItem: vueWaitLoader(async function (store) {
      const payload = {
        intStoreGroupId: this.selectedStoreGroup.id,
        storeId: store.storeId,
        accountCode: store.accountCode
      }
      const res = await this.saveIntStore(payload)
      if (res) {
        const message = this.$t('Integration.StoreSuccessUpdated', { name: store.storeName })
        this.notifyShow({ message })
      }
    }, 'storeCodeUpdateB2B')
  },

  async beforeMount () {
    await this.fetchStoreGroupList()
    await this.fetchStoreList()
  }
}
</script>
