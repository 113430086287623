<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    div
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-15(
      :list="IntStoreGroupList",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      fieldKey="name"
    )

  template(slot="content-center")
    SelectedButtons.m-bottom-25(
      v-model="selectedType",
      :data="types",
      type="pageTab"
    )

    TableLoading(v-if="isFetchingData")

    IntegrationTable(
    v-else,
    :fields="fields",
    @onChange="updateItem"
    :data="configList"
  )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import IntegrationTable from '@/view/pages/Integration/components/Table'

//

import converCaptalize from '@/utils/convert-capitalize'
import { getConfigFields } from './field'
import { mapActions } from 'vuex'

export default {
  name: 'IntegrationB2BDataConfig',

  /**
  **/

  data () {
    return {
      IntStoreGroupList: [],
      selectedStoreGroup: {},
      configList: [],
      types: [
        {
          text: this.$t('B2B.breadcrumb_IntegrationB2bStore'),
          value: 'store'
        },
        {
          text: this.$t('B2B.breadcrumb_IntegrationB2bTax'),
          value: 'tax'
        },
        {
          text: this.$t('B2B.breadcrumb_IntegrationB2bUnit'),
          value: 'unit'
        }
      ],
      selectedType: 'store'
    }
  },

  /**
  **/

  components: {
    ToggleOverGroups,
    IntegrationTable,
  },

  /**
  **/

  computed: {

    itemId () {
      return this.$route.params.id
    },

    fields () {
      return getConfigFields(this.$t, this.selectedType)
    },

    isFetchingData () {
      return this.$wait.is('fetchingB2bDefinitionConfigList')
    }
  },

  /**
  **/

  methods: {

    ...mapActions('IntegrationB2b', [
      'fetchingB2bDefinitionStoreGroups',
      'fetchingB2bDefinitionConfigList',
      'saveB2bDefinitionConfigList'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async pageInit () {
      const response = await this.fetchingB2bDefinitionStoreGroups(this.itemId)
      if (!response) return
      this.IntStoreGroupList = response.data.storeGroupList
      this.selectedStoreGroup = this.IntStoreGroupList[0]
      this.getConfigList()
    },

    async getConfigList () {
      const payload = {
        actionType: this.selectedType,
        params: {
          IntB2BDefinitionId: this.itemId,
          StoreGroupId: this.selectedStoreGroup.id
        }
      }
      const res = await this.fetchingB2bDefinitionConfigList(payload)
      if (!res) return
      const dataKey = `intB2B${converCaptalize(this.selectedType)}List`
      this.configList = res.data[dataKey]
    },

    onChangeStoreGroup (storeGroup) {
      this.selectedStoreGroup = storeGroup
      this.getConfigList()
    },

    updateItem (row) {
      const payload = {
        ...row,
        intB2BDefinitionId: this.itemId,
        storeGroupId: this.selectedStoreGroup.id
      }

      this.saveB2bDefinitionConfigList({
        actionType: this.selectedType,
        params: {
          ...payload
        }
      }).then(res => {
        if (res) {
          const message = this.$t('B2B.successUpdatedIntegrationCode', { name: row[`${this.selectedType}Name`] })
          this.notifyShow({ message })
        }
      })
    }
  },

  watch: {
    selectedType () {
      this.getConfigList()
    }
  },

  mounted () {
    this.pageInit()
  }
}
</script>
