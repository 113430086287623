<template lang="pug">
Page(titleType="custom")
  template(slot="header-title")
    .header-title-left
      span {{ $t('Integrations.pageHeader_SalesForecasting') }}
  template(slot="content-center")
    TableView(
      :data="ForecastMapList"
      :fields="fields"
      :readOnly="true"
      :componentName="'ForecastMapTable'"
    )

    portal(to="menuItemList")
      div.menu-item-select(slot-scope="row" v-if="renderComponent")
        custom-search.form-item-select.m-top-10(
          component-name="menu-item-list"
          name="menuItemList",
          label="name",
          :placeholder="$t('Integrations.search_menuItem')"
          :customEmit="row"
          :debounce="300"
          :isLoading="false"
          :options="selectOptionsMenuItem"
          :disabled="row.menuItemList.length === 3"
          :isCustomSituation="true"
          :on-search="getMenuItemListOptions"
          @onClickInput="setSelectedRow"
          @input="selectMenuItem"
          @afterSelected="actionAfterSelectMenuItem(row)"
        )
        .selected-items.m-left-10(v-if="renderComponent")
          Button.m-left-10.m-top-5(
            seashell
            size="xsmall"
            v-for="(item,index) in row.menuItemList",
            :key="index",
            :id="`selected-menu-item-${index}`"
            @click.stop="removeMenuItem({item, row})"
          )
            span {{ item.name }}
            Icon.m-left-5(
              name="icon-popup-close"
              :id="`selected-despatch-list-item-delete-${index}`"
            )


</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'


export default {
  name: 'SalesForecasting',

  data () {
    return {
      selectOptionsMenuItem: [],
      selectedMenuItems: [],
      selectedStoreGroup: null,
      renderComponent: true,
      selectedRow: null
    }
  },

  async created () {
    await this.getForecastMenuItemMapList()
  },

  computed: {
    ...mapGetters('SalesForecasting', ['ForecastMapList']),


    fields () {
      return [
        {
          name: 'storeGroupName',
          is_visible: true,
          no_tooltip: true,
          short_title: this.$t('Dictionary.StoreGroup'),
          title: this.$t('Dictionary.StoreGroup')
        },
        {
          name: 'menuItemList',
          is_visible: true,
          type: 'portal',
          short_title: this.$t('Integration.menuItems'),
          title: this.$t('Integration.menuItems')
        }
      ]
    }
  },
  methods: {
    ...mapActions('SalesForecasting', ['getFilteredMenuItems', 'getForecastMenuItemMapList', 'updateForecastMenuItem']),

    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
  
    removeMenuItem: vueWaitLoader(async function ({item, row}) {
      let remainingMenuItemIds = row.menuItemList.map(i => i.id)
      remainingMenuItemIds.splice(remainingMenuItemIds.indexOf(item.id), 1)
      const payload = {
        menuItemIds: remainingMenuItemIds,
        storeGroupId: row.storeGroupId
      }
      const res = await this.updateForecastMenuItem(payload)
      if (res) {
        row.menuItemList = res.data.menuItems
        this.forceRerender()
      }
    }, 'removeMenuItemsFromStoreGroups'),

    async selectMenuItem (item) {
      if (item.customEmit.menuItemList && item.customEmit.menuItemList.length) {
        this.selectedMenuItems = item.customEmit.menuItemList.map(i => i.id)
        this.selectedMenuItems.push(item.option.id)
      } else this.selectedMenuItems.push(item.option.id)
      this.forceRerender()
    },

    actionAfterSelectMenuItem: vueWaitLoader(async function (item) {
      this.selectedStoreGroup = item.storeGroupId
      const payload = {
        storeGroupId: this.selectedStoreGroup,
        menuItemIds: this.selectedMenuItems
      }
      const res = await this.updateForecastMenuItem(payload)
      if (res) {
        item.menuItemList = res.data.menuItems
        this.selectedMenuItems = []
      }
      this.forceRerender()
    }, 'matchStoreGroupWithMenuItems'),

    setSelectedRow (item) {
      this.selectedRow = item
    },

    getMenuItemListOptions (search) {
      if (search.length < 2) return
      this.selectOptionsMenuItem = []
        const payload = {          
          pageNumber: 1,
          pageSize: 999999,
          revenueCenterIdList: [],
          menuItemGroupIdList: [],
          storeGroupIdList: [this.selectedRow.storeGroupId],
          showCondiments: true,
          text: search,
          showAll: true
        }
        this.getFilteredMenuItems(payload)
          .then(res => {
            if (res) {
              this.selectOptionsMenuItem = res.data.itemList
            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
  .integration-sales-forecasting-content {
    margin-top: 10%;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: $font-size-bigger;
      margin-top: 30px;
      font-weight: 420;
      color: #191818;
    }
    &-description {
      color: #91979C;
      font-weight: 420;
      font-size: $font-size-normal;
    }
    &-phone {
      font-weight: 450;
      margin-top: 40px;
      font-size: $font-size-bigger;
      display: flex;
      align-items: center;
    }
  }

  .form-item-select {
    min-width: 300px;
    max-width: 400px;
  }

  .menu-item-select {
    display: flex;
    margin-bottom: 10px;
  }
  .selected-items {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
   
</style>
