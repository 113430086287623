var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            staticClass: "m-left-10",
            attrs: {
              links: _vm.pageTabLinks,
              translationGroup: "WarehouseManagement",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.IntWmsStoreGroupList,
              selectedItem: _vm.selectedStoreGroup,
              selectedKey: "storeGroupId",
              fieldKey: "storeGroupName",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingSettingInfo
            ? _c("TableLoading")
            : _c(
                "SettingsForm",
                {
                  attrs: {
                    form: _vm.form,
                    isLoadingAction: _vm.isLoading,
                    onSubmitAction: _vm.onSubmitForm,
                  },
                },
                [
                  _c("template", { slot: "extraField" }, [
                    _c(
                      "div",
                      { staticClass: "checkField" },
                      [
                        _c("CustomCheckbox", {
                          staticClass: "m-right-25",
                          attrs: {
                            label: _vm.$t("Integration.syncApprovedOrders"),
                          },
                          model: {
                            value: _vm.form.syncApprovedOrders,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "syncApprovedOrders", $$v)
                            },
                            expression: "form.syncApprovedOrders",
                          },
                        }),
                        _c("CustomCheckbox", {
                          attrs: {
                            label: _vm.$t("Integration.syncTransferRequests"),
                          },
                          model: {
                            value: _vm.form.syncTransferRequests,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "syncTransferRequests", $$v)
                            },
                            expression: "form.syncTransferRequests",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }