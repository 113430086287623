var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            staticClass: "m-left-10",
            attrs: { links: _vm.pageTabLinks, translationGroup: "B2B" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            attrs: {
              list: _vm.IntStoreGroupList,
              selectedItem: _vm.selectedStoreGroup,
              selectedKey: "storeGroupId",
              fieldKey: "storeGroupName",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingSettingInfo
            ? _c("TableLoading")
            : _c(
                "SettingsForm",
                {
                  attrs: {
                    form: _vm.form,
                    isLoadingAction: _vm.isLoading,
                    onSubmitAction: _vm.onSubmitForm,
                  },
                },
                [
                  _c("template", { slot: "extraField" }, [
                    _c("div", { staticClass: "Form-item required" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Integration.StoreGroup_FormField_termPeriod"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customTextInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:32",
                                expression: "'required|max:32'",
                              },
                            ],
                            attrs: {
                              name: "termPeriod",
                              error: _vm.veeErrors.has("termPeriod"),
                              "data-vv-as": _vm.$t(
                                "Integration.StoreGroup_FormField_termPeriod"
                              ),
                              id: "input-settings-form-field-termPeriod",
                              isDelayInput: true,
                            },
                            model: {
                              value: _vm.form.termPeriod,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "termPeriod", $$v)
                              },
                              expression: "form.termPeriod",
                            },
                          }),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("termPeriod"),
                                  expression: "veeErrors.has('termPeriod')",
                                },
                              ],
                              staticClass: "Form-item-help is-danger",
                            },
                            [_vm._v(_vm._s(_vm.veeErrors.first("termPeriod")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "checkField" },
                      [
                        _c("CustomCheckbox", {
                          staticClass: "m-right-25",
                          attrs: {
                            label: _vm.$t("Integration.syncApprovedOrders"),
                          },
                          model: {
                            value: _vm.form.syncApprovedOrders,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "syncApprovedOrders", $$v)
                            },
                            expression: "form.syncApprovedOrders",
                          },
                        }),
                        _c("CustomCheckbox", {
                          attrs: {
                            label: _vm.$t("Integration.syncDespatches"),
                          },
                          model: {
                            value: _vm.form.syncDespatches,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "syncDespatches", $$v)
                            },
                            expression: "form.syncDespatches",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }