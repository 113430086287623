<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    PageHeaderTabs.m-left-10(
      :links="pageTabLinks",
      translationGroup="PortalPlusEDespatch"
    )
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-15(
      :list="storeGroups",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      selectedKey="storeGroupId",
      fieldKey="storeGroupName"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingSettingInfo || isFetchingData")
    StoreGroupConfigForm(
      v-else
      :form="form",
      :isPortalPlus="true"
      :isLoadingAction="isLoading"
      @onSubmitAction="onSubmitForm"
    )
</template>

<script>
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import StoreGroupConfigForm from '@/view/pages/Integration/components/SettingsForm/storeGroupConfigForm'
import { vueWaitLoader } from '@/utils/baseOperations'
//

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'PortalPlusEDespatch',
  /**
  **/
  data () {
    return {
      form: {
        username: null,
        password: null,
        apiAddress: null,
        folderIntId: null,
        sourceIntId: null
      },
      storeGroups: []
    }
  },
  /**
  **/
  components: {
    ToggleOverGroups,
    StoreGroupConfigForm
  },

  computed: {
    ...mapGetters('EConversionStoreGroupConfig', ['pageTabLinks', 'selectedStoreGroup', 'storeGroupList']),
    ...mapGetters('Settings', ['UserInfo']),

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isFetchingSettingInfo () {
      return this.$wait.is(['getPortalPlusStoreGroups', 'configPageLoading'])
    },
    isFetchingData () {
      return this.$wait.is(['getPortalPlusInfo'])
    },

    isLoading () {
      return this.$wait.is(['storeGroupConfigPortalPlus'])
    }
  },

  methods: {
    ...mapActions('EConversionStoreGroupConfig', [
      'getPortalPlusStoreGroups',
      'updatePortalPlusSettings',
      'getPortalPlusInfo'
    ]),

    ...mapMutations('EConversionStoreGroupConfig', ['SET_SELECTED_STORE_GROUP']),

    getIntegrationStoreGroupWithParams () {
      const params = {
        StoreGroupId: this.selectedStoreGroup.storeGroupId,
        IntegrationProductId: this.IntegrationProductId
      }
      this.getPortalPlusInfo(params)
        .then(res => {
          if (res.data.eDespatchStoreGroup) {
            this.form = res.data.eDespatchStoreGroup
          } else this.form = {}
        })
    },

    async getStoreGroups () {
      const payload = {
        IntegrationProductId: this.$route.params.IntegrationProductId
      }
      await this.getPortalPlusStoreGroups(payload).then(res => {
        this.storeGroups = res.data.eDespatchStoreGroupList
        const defaultStore = this.storeGroups[0]
        const selectedStoreGroup = this.selectedStoreGroup.hasOwnProperty('storeGroupId') ? this.selectedStoreGroup : defaultStore
        this.onChangeStoreGroup(selectedStoreGroup)
      })
    },

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async onChangeStoreGroup (storeGroup) {
      this.SET_SELECTED_STORE_GROUP(storeGroup)
      await this.getIntegrationStoreGroupWithParams()
    },

    resetForm () {
      this.form.username = null
      this.form.apiAddress = null
      this.form.password = null
      this.form.folderIntId = null
      this.form.sourceIntId = null
    },

    onSubmitForm: vueWaitLoader(async function (form) {
      if (this.form.username === '') this.resetForm()


      let params = {
        ...form,
        isPasswordFilled: !!form.password,
        integrationProductId: this.IntegrationProductId,
        storeGroupId: this.selectedStoreGroup.storeGroupId
      }
      const res = await this.updatePortalPlusSettings(params)
      if (!res) return
      const message = this.$t('Integration.StoreGroupSuccessUpdated')
      this.notifyShow({ message })
      this.getIntegrationStoreGroupWithParams()
    }, 'storeGroupConfigPortalPlus')
  },

  async mounted () {
    this.$wait.start('configPageLoading')
    await this.getStoreGroups()
    this.$wait.end('configPageLoading')
  }
}
</script>

<style lang="scss" scoped>

  .checkField {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $input-size;
    margin-top: 27.5px; // for label and lineheight
    margin-bottom: 48px;
  }

  :deep(.Form-item + .Form-item) {
    margin-top: 0 !important;
  }
  :deep(.form-item-group) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .Form-item {
      width: 100% !important;
    }
  }

</style>
