import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    forecastMapList: []
  },

  getters: {
    ForecastMapList: state => state.forecastMapList
  },

  actions: {
    async getFilteredMenuItems({ commit }, payload) {
      const results = await requestHandler({
        title: 'getFilteredMenuItems',
        methodName: 'getFilteredMenuItems',
        method: () => {
          return HTTP.post('Pos/menuitem/filter', payload)
        },
        success: result => result
      })
      return results
    },

    async getForecastMenuItemMapList({ commit }) {
      const results = await requestHandler({
        title: 'getForecastMenuItemMapList',
        methodName: 'getForecastMenuItemMapList',
        method: () => {
          return HTTP.get('Integration/salesforecast/config/all')
        },
        success: result => {
          commit('SET_FORECAST_MAP_LIST', result.data.salesForecastMenuItemMapList)
          return result
        }
      })
      return results
    },
    async updateForecastMenuItem({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateForecastMenuItem',
        methodName: 'updateForecastMenuItem',
        method: () => {
          return HTTP.put('Integration/salesforecast/config/menuitem', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_FORECAST_MAP_LIST(state, data) {
      state.forecastMapList = data
    }
  }
}
