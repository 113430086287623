var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "IntegrationMassecPageContent" }, [
          _c("div", { staticClass: "MassecImage" }, [
            _c("img", {
              attrs: { src: "/static/images/massec_calculate.png" },
            }),
          ]),
          _c("div", { staticClass: "MassecContent" }, [
            _c("div", { staticClass: "MassecContentLogo" }, [
              _c("img", {
                attrs: { src: "/static/images/massec_page_logo.png" },
              }),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("Integration.massecContentFirst"))),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }