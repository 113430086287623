import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    pageHeaderLinks: [
      'IntegrationAccountingLogo',
      'IntegrationAccountingLogoStore',
      'IntegrationAccountingLogoTax',
      'IntegrationAccountingLogoSupplier',
      'IntegrationAccountingLogoItemGroup',
      'IntegrationAccountingLogoWasteGroup'
    ],
    pageHeaderXeroLinks: [
      'IntegrationAccountingXero',
      'IntegrationAccountingXeroStore',
      'IntegrationAccountingXeroTax',
      'IntegrationAccountingXeroSupplier',
      'IntegrationAccountingXeroItemGroup',
      'IntegrationAccountingXeroWasteGroup'
    ],
    intStoreGroupList: [],
    integrationStoreGroupList: []
  },

  getters: {
    PageHeaderLinks: state => state.pageHeaderLinks,
    PageHeaderXeroLinks: state => state.pageHeaderXeroLinks,
    IntStoreGroupList: state => state.intStoreGroupList,
    IntegrationStoreGroupList: state => state.integrationStoreGroupList
  },

  actions: {
    /* Integration Store Groups */
    async saveIntegrationStoreGroup({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntegrationStoreGroup',
        methodName: 'saveIntegrationStoreGroup',
        method: () => {
          return HTTP.post('integration/accounting/config/storegroup/save', payload)
        },
        success: result => result
      })
      return results
    },

    async getIntegrationStoreGroup({ commit }, params) {
      // params.IntegrationType = 1 // This is static for now
      const results = await requestHandler({
        title: 'getIntegrationStoreGroup',
        methodName: 'getIntegrationStoreGroup',
        method: () => {
          return HTTP.get('integration/accounting/config/storegroup', { params })
        },
        success: result => {
          commit('GET_INT_STORE_GROUP_LIST', result.data.intStoreGroup)
          return result
        }
      })
      return results
    },

    async fetchIntStoreGroupList({ commit }, params) {
      const results = await requestHandler({
        title: 'getIntStoreGroups',
        methodName: 'getIntStoreGroups',
        method: () => {
          return HTTP.get('integration/accounting/config/storegroup/all', { params })
        },
        success: result => {
          commit('SET_INT_STORE_GROUP_LIST', result.data.intStoreGroupList)
          return result
        }
      })
      return results
    },

    /* Integration Stores */

    async getIntStoreList({ commit }, params) {
      const results = await requestHandler({
        title: 'getIntStoreList',
        methodName: 'getIntStoreList',
        method: () => {
          return HTTP.get('integration/accounting/config/store/all', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async saveIntStore({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntStore',
        methodName: 'saveIntStore',
        method: () => {
          return HTTP.post('integration/accounting/config/store/save', payload)
        },
        success: result => result
      })
      return results
    },

    /* Integration Tax */

    async getIntTaxList({ commit }, params) {
      const results = await requestHandler({
        title: 'getIntTaxList',
        methodName: 'getIntTaxList',
        method: () => {
          return HTTP.get('integration/accounting/config/tax/all', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async saveIntTax({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntTax',
        methodName: 'saveIntTax',
        method: () => {
          return HTTP.post('integration/accounting/config/tax/save', payload)
        },
        success: result => result
      })
      return results
    },

    /* Integration Supplier */

    async getIntSupplierList({ commit }, params) {
      const results = await requestHandler({
        title: 'getIntSupplierList',
        methodName: 'getIntSupplierList',
        method: () => {
          return HTTP.get('integration/accounting/config/supplier/all', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async saveIntSupplier({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntSupplier',
        methodName: 'saveIntSupplier',
        method: () => {
          return HTTP.post('integration/accounting/config/supplier/save', payload)
        },
        success: result => result
      })
      return results
    },

    /* Integration ItemGroup */

    async getIntItemGroupList({ commit }, params) {
      const results = await requestHandler({
        title: 'getIntItemGroupList',
        methodName: 'getIntItemGroupList',
        method: () => {
          return HTTP.get('integration/accounting/config/itemgroup/all', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async saveIntItemGroup({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntItemGroup',
        methodName: 'saveIntItemGroup',
        method: () => {
          return HTTP.post('integration/accounting/config/itemgroup/save', payload)
        },
        success: result => result
      })
      return results
    },

    /* Integration ItemGroup */

    async getIntWasteGroupList({ commit }, params) {
      const results = await requestHandler({
        title: 'getIntWasteGroupList',
        methodName: 'getIntWasteGroupList',
        method: () => {
          return HTTP.get('integration/accounting/config/wastegroup/all', { params })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async saveIntWasteGroup({ commit }, payload) {
      const results = await requestHandler({
        title: 'saveIntWasteGroup',
        methodName: 'saveIntWasteGroup',
        method: () => {
          return HTTP.post('integration/accounting/config/wastegroup/save', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_INT_STORE_GROUP_LIST(state, data) {
      state.intStoreGroupList = data
    },
    GET_INT_STORE_GROUP_LIST(state, data) {
      state.integrationStoreGroupList = data
    }
  }
}
