var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c(
        "template",
        { slot: "header-right-content" },
        [
          _c(
            "Button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEmpytList,
                  expression: "!isEmpytList",
                },
              ],
              attrs: {
                primary: "",
                size: "medium",
                id: "integration-new-b2b-b2b",
              },
              on: { click: _vm.gotoNewIntegration },
            },
            [_vm._v(_vm._s(_vm.$t("B2B.addNewB2bIntegration")))]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingList
            ? _c("TableLoading")
            : [
                _vm.isEmpytList
                  ? _c("EmptyState", {
                      attrs: {
                        title: _vm.$t("Global.emptyState_Title", {
                          field: _vm.$t("Dictionary.Integration_B2B"),
                        }),
                        description: _vm.$t("Global.emptyState_Description", {
                          field: _vm.$t("Dictionary.Integration_B2B"),
                        }),
                        buttonText: _vm.$t("B2B.addNewB2bIntegration"),
                      },
                      on: { emptyAddButton: _vm.gotoNewIntegration },
                    })
                  : _c("BoxView", {
                      attrs: {
                        fields: _vm.b2bGetFields,
                        actions: ["edit", "remove"],
                        data: _vm.B2bDefinitionList,
                        extraClass: { forContent: "box-flex-row m-auto" },
                        componentName: "B2BIntegrationBox",
                      },
                      on: {
                        delegateOnItemShow: _vm.onItemClick,
                        delegateOnItemEdit: function ($event) {
                          return _vm.onActionClick("edit", $event)
                        },
                        delegateOnItemRemove: function ($event) {
                          return _vm.onActionClick("delete", $event)
                        },
                      },
                    }),
              ],
          _c("router-view"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }