var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c(
        "template",
        { slot: "header-left-dropdown" },
        [
          _c("PageHeaderTabs", {
            staticClass: "m-left-10",
            attrs: { links: _vm.pageTabLinks, translationGroup: "eLogo" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            staticClass: "m-bottom-15",
            attrs: {
              list: _vm.storeGroups,
              selectedItem: _vm.selectedStoreGroup,
              selectedKey: "storeGroupId",
              fieldKey: "storeGroupName",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _vm.isFetchingSettingInfo || _vm.isFetchingData
            ? _c("TableLoading")
            : _c("StoreGroupConfigForm", {
                attrs: {
                  form: _vm.form,
                  taxNumber: _vm.selectedStoreGroup.taxNumber,
                  isLoadingAction: _vm.isLoading,
                },
                on: { onSubmitAction: _vm.onSubmitForm },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }