import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
export default {
  namespaced: true,
  state: {
    selectedStoreGroup: {},
    aliasData: [],
    pageTabLinks: [
      /* 'ProtelIntegratorStoreGroupConfig' */
    ]
  },

  getters: {
    pageTabLinks: state => state.pageTabLinks,
    selectedStoreGroup: state => state.selectedStoreGroup,
    AliasData: state => state.aliasData
  },

  actions: {

    async getProtelIntegratorInfo({ commit }, payload) {
      const loadingName = 'getProtelIntegratorInfo'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/einvoice/config/storegroup', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async getProtelIntegratorStoreGroups({ commit }, payload) {
      const loadingName = 'getProtelIntegratorStoreGroups'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/einvoice/config/storegroup/all', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async updateProtelIntegratorSettings({ commit }, payload) {
      let results = await requestHandler({
        title: 'updateProtelIntegratorSettings',
        methodName: 'updateProtelIntegratorSettings',
        method: () => {
          return HTTP.post('integration/einvoice/config/save', payload)
        },
        success: result => result
      })

      return results
    },

    async getProtelIntegratorAliasData({ commit }, taxNumber) {
      const loadingName = 'getProtelIntegratorAliasData'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(`integration/einvoice/config/alias?TaxNumber=${taxNumber}`)
        },
        success: result => {
          commit('GET_ALIAS_DATA', result.data)
        }
      })
      return results
    },

    async getPortalPlusStoreGroups({ commit }, payload) {
      const loadingName = 'getPortalPlusStoreGroups'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/edespatch/config/storegroup/all', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },
    async updatePortalPlusSettings({ commit }, payload) {
      let results = await requestHandler({
        title: 'updatePortalPlusSettings',
        methodName: 'updatePortalPlusSettings',
        method: () => {
          return HTTP.post('integration/edespatch/config/save', payload)
        },
        success: result => result
      })

      return results
    },
    async getPortalPlusInfo({ commit }, payload) {
      const loadingName = 'getPortalPlusInfo'
      let results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('integration/edespatch/config/storegroup', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_SELECTED_STORE_GROUP(state, storeGroup) {
      state.selectedStoreGroup = {
        ...storeGroup
      }
    },

    RESET_STORE_GROUP(state) {
      state.selectedStoreGroup = {}
    },

    GET_ALIAS_DATA(state, data) {
      state.aliasData = data.aliasList
    }
  }
}
