import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import RandomRange from '@/utils/random-range'

export default {
  namespaced: true,

  state: {
    IntegrationProductList: [],
    massecIsActive: false,
    iBarInfo: null,
    xero: {
      randomState: RandomRange(1, 999999),
      accessToken: null,
      codeURL: null,
      storeGroup: {},
      xeroIntegrationProductId: null
    }
  },

  getters: {
    IntegrationProductList: state => state.IntegrationProductList,
    massecIsActive: state => state.massecIsActive,
    randomState: state => state.xero.randomState,
    xeroStoreGroup: state => state.xero.xeroStoreGroupId,
    xeroIntegrationProductId: state => state.xero.xeroIntegrationProductId,
    iBarInfo: state => state.iBarInfo
  },

  actions: {
    async fetchIntegrationProductList({ commit }) {
      const loadingName = 'fetchIntegrationProductList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('IntegrationProduct/all')
        },
        success: result => {
          commit('SET_INTEGRATION_PRODUCT_LIST', result.data)
          return result
        }
      })
      return results
    },

    async updateIntegrationStatus({ commit, dispatch }, payload) {
      const loadingName = 'updateIntegrationStatus'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('IntegrationProduct/status', payload)
        },
        success: result => {
          dispatch('checkIntegrationMassecStatus')
        }
      })
      return results
    },
    async updateIBarIntegrationStatus({ commit, dispatch }, payload) {
      const loadingName = 'updateIBarIntegrationStatus'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('IntegrationProduct/ibar/status', payload)
        },
        success: result => {
          dispatch('checkIntegrationIBarStatus')
        }
      })
      return results
    },

    async checkIntegrationIBarStatus({ commit }) {
      const loadingName = 'checkIntegrationIBarStatus'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('IntegrationProduct/ibar/info')
        },
        success: result => {
          commit('SET_IBAR_ACTIVE_VALUE', result.data)
          return result
        }
      })
      return results
    },
    
    async checkIntegrationMassecStatus({ commit }) {
      const loadingName = 'checkIntegrationMassecStatus'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('IntegrationProduct/check/massec')
        },
        success: result => {
          commit('SET_MASSEC_ACTIVE_VALUE', result.data.isActive)
          return result
        }
      })
      return results
    },

    async getAllNutritionList({ commit, dispatch }) {
      const loadingName = 'getAllNutritionList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Nutrition/all')
        },
        success: result => result
      })
      return results
    },

    async updateNutritionSet({ commit, dispatch }, payload) {
      const loadingName = 'updateNutritionSet'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Nutrition', payload)
        },
        success: result => result
      })
      return results
    },

    async getCompanyNameForXero({ commit, dispatch }, payload) {
      const loadingName = 'getCompanyNameForXero'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('integration/accounting/config/xero/callback', payload)
        },
        success: result => result
      })
      return results
    },

    async disconnectFromXeroIntegration({ commit, dispatch }, payload) {
      const loadingName = 'disconnectFromXeroIntegration'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('integration/accounting/config/xero/disconnect', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {

    SET_INTEGRATION_PRODUCT_LIST(state, data) {
      state.IntegrationProductList = data.integrationProductList
    },

    SET_MASSEC_ACTIVE_VALUE(state, value) {
      state.massecIsActive = value
    },
    SET_XERO_STORE_GROUP_ID(state, value) {
      state.xero.storeGroup = value
    },
    SET_XERO_INTEGRATION_PRODUCT_ID(state, value) {
      state.xero.xeroIntegrationProductId = value
    },
    SET_IBAR_ACTIVE_VALUE(state, value) {
      state.iBarInfo = value
    }
  }

}
