var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "IntegrationTable" }, [
    _c(
      "div",
      { staticClass: "custom-table", class: { hasAction: _vm.hasAction } },
      [
        _c(
          "div",
          { staticClass: "row header" },
          _vm._l(_vm.fields, function (field, index) {
            return _c(
              "div",
              {
                key: `integration-table-row-col-header-${index}`,
                staticClass: "col",
                attrs: { id: `integration-table-row-col-header-${index}` },
              },
              [
                field.hasCheckbox
                  ? [
                      _c("CustomCheckbox", {
                        attrs: {
                          id: `integration-table-row-all-selected-checkbox`,
                          label: field.text,
                          checked: _vm.isAllSelected,
                        },
                        on: {
                          change: (isChecked) =>
                            _vm.toggleAllSelected(isChecked),
                        },
                      }),
                    ]
                  : [_c("span", [_vm._v(_vm._s(field.text))])],
              ],
              2
            )
          }),
          0
        ),
        _vm._l(_vm.data, function (row, rowIndex) {
          return _c(
            "div",
            { key: `integration-table-row-${rowIndex}`, staticClass: "row" },
            [
              _vm._l(_vm.fields, function (field, index) {
                return _c(
                  "div",
                  {
                    key: `integration-table-row-col-${rowIndex}-${index}`,
                    staticClass: "col",
                    attrs: {
                      id: `integration-table-row-col-${rowIndex}-${index}`,
                    },
                  },
                  [
                    field.hasCheckbox
                      ? [
                          _c("CustomCheckbox", {
                            attrs: {
                              id: `integration-table-row-${rowIndex}-checkbox`,
                              label: row[field.name],
                              disabled: field.checkboxDisabled
                                ? field.checkboxDisabled(row)
                                : false,
                              checked: !!_vm.selectedItems.find(
                                (item) =>
                                  item[field.uniqueKey] === row[field.uniqueKey]
                              ),
                            },
                            on: {
                              change: (isChecked) =>
                                _vm.onItemSelect(isChecked, row),
                            },
                          }),
                        ]
                      : field.isEditable || field.readonlyValue
                      ? [
                          field.isEditable
                            ? _c(
                                "div",
                                { staticClass: "flexbox align-center" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form[rowIndex][field.name],
                                        expression:
                                          "form[rowIndex][field.name]",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: _vm.validateRules(field, row),
                                        expression: "validateRules(field, row)",
                                      },
                                    ],
                                    ref: `${field.name}_${rowIndex}`,
                                    refInFor: true,
                                    staticClass: "txt",
                                    class: {
                                      "is-danger": _vm.veeErrors.has(
                                        `input-integration-${field.name}-${rowIndex}`
                                      ),
                                    },
                                    attrs: {
                                      name: `input-integration-${field.name}-${rowIndex}`,
                                      autocomplete: "off",
                                      "data-vv-as": field.text,
                                      id: `input-integration-${field.name}-${rowIndex}`,
                                    },
                                    domProps: {
                                      value: _vm.form[rowIndex][field.name],
                                    },
                                    on: {
                                      keypress: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        if (
                                          $event.target !== $event.currentTarget
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.onFocusInput(
                                          field.name,
                                          rowIndex
                                        )
                                      },
                                      change: function ($event) {
                                        _vm.isFocusable
                                          ? _vm.onChangeValue(
                                              field.name,
                                              row,
                                              rowIndex
                                            )
                                          : null
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.form[rowIndex],
                                          field.name,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("Button", {
                                    staticClass: "m-left-10",
                                    attrs: {
                                      primary: "",
                                      size: "small",
                                      tabindex: "-1",
                                      id: `button-integration-${field.name}-${rowIndex}`,
                                      variant: "icon",
                                      disabled:
                                        _vm.veeErrors.has(
                                          `input-integration-${field.name}-${rowIndex}`
                                        ) ||
                                        (!_vm.nullable &&
                                          !!!_vm.form[rowIndex][field.name]) ||
                                        _vm.isLoading,
                                      iconName: "icon-btn-success",
                                      justIcon: true,
                                      iconClass: "icon-btn-success",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onChangeValue(
                                          field.name,
                                          row,
                                          rowIndex
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          field.readonlyValue
                            ? _c("div", { staticClass: "flexbox" }, [
                                _c("input", {
                                  staticClass: "txt",
                                  attrs: {
                                    placeholder: field.emptyText,
                                    disabled: true,
                                    readonly: true,
                                  },
                                  domProps: { value: row[field.name] },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      : [_c("span", [_vm._v(_vm._s(row[field.name]))])],
                  ],
                  2
                )
              }),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("portalTarget", {
                    attrs: { name: "integrationRowPortal", "slot-props": row },
                  }),
                ],
                1
              ),
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }