var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c(
        "template",
        { slot: "overgroups-left" },
        [
          _c("ToggleOverGroups", {
            staticClass: "m-bottom-15",
            attrs: {
              list: _vm.IntStoreGroupList,
              selectedItem: _vm.selectedStoreGroup,
              fieldKey: "name",
            },
            on: { delegateChangeSelectedItem: _vm.onChangeStoreGroup },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "content-center" },
        [
          _c("SelectedButtons", {
            staticClass: "m-bottom-25",
            attrs: { data: _vm.types, type: "pageTab" },
            model: {
              value: _vm.selectedType,
              callback: function ($$v) {
                _vm.selectedType = $$v
              },
              expression: "selectedType",
            },
          }),
          _vm.isFetchingData
            ? _c("TableLoading")
            : _c("IntegrationTable", {
                attrs: { fields: _vm.fields, data: _vm.configList },
                on: { onChange: _vm.updateItem },
              }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }