var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c("template", { slot: "header-title" }, [
        _c("div", { staticClass: "header-title-left" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("Integrations.pageHeader_SalesForecasting"))),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "content-center" },
        [
          _c("TableView", {
            attrs: {
              data: _vm.ForecastMapList,
              fields: _vm.fields,
              readOnly: true,
              componentName: "ForecastMapTable",
            },
          }),
          _c("portal", {
            attrs: { to: "menuItemList" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (row) {
                    return _vm.renderComponent
                      ? _c(
                          "div",
                          { staticClass: "menu-item-select" },
                          [
                            _c("custom-search", {
                              staticClass: "form-item-select m-top-10",
                              attrs: {
                                "component-name": "menu-item-list",
                                name: "menuItemList",
                                label: "name",
                                placeholder: _vm.$t(
                                  "Integrations.search_menuItem"
                                ),
                                customEmit: row,
                                debounce: 300,
                                isLoading: false,
                                options: _vm.selectOptionsMenuItem,
                                disabled: row.menuItemList.length === 3,
                                isCustomSituation: true,
                                "on-search": _vm.getMenuItemListOptions,
                              },
                              on: {
                                onClickInput: _vm.setSelectedRow,
                                input: _vm.selectMenuItem,
                                afterSelected: function ($event) {
                                  return _vm.actionAfterSelectMenuItem(row)
                                },
                              },
                            }),
                            _vm.renderComponent
                              ? _c(
                                  "div",
                                  { staticClass: "selected-items m-left-10" },
                                  _vm._l(
                                    row.menuItemList,
                                    function (item, index) {
                                      return _c(
                                        "Button",
                                        {
                                          key: index,
                                          staticClass: "m-left-10 m-top-5",
                                          attrs: {
                                            seashell: "",
                                            size: "xsmall",
                                            id: `selected-menu-item-${index}`,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeMenuItem({
                                                item,
                                                row,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name)),
                                          ]),
                                          _c("Icon", {
                                            staticClass: "m-left-5",
                                            attrs: {
                                              name: "icon-popup-close",
                                              id: `selected-despatch-list-item-delete-${index}`,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e()
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }