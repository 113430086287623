var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "Form",
      attrs: { id: "integration-store-grop-setting-form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmitStoreGroupIntegration.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "Form-row" }, [
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item firstname required" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_companyNumber"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.companyNumber,
                    expression: "form.companyNumber",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:32",
                    expression: "'required|max:32'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("companyNumber") },
                attrs: {
                  name: "companyNumber",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_companyNumber"
                  ),
                  type: "text",
                  id: "input-integration-form-field-company-number",
                },
                domProps: { value: _vm.form.companyNumber },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "companyNumber", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("companyNumber"),
                    expression: "veeErrors.has('companyNumber')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("companyNumber")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item username required" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_userName"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.username,
                    expression: "form.username",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:32",
                    expression: "'required|max:32'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("new-username") },
                attrs: {
                  name: "new-username",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_userName"
                  ),
                  type: "text",
                  id: "input-settings-form-field-username",
                },
                domProps: { value: _vm.form.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "username", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("new-username"),
                    expression: "veeErrors.has('new-username')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("new-username")))]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "Form-row" }, [
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item password required" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_password"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: !_vm.form.id ? "required|max:32" : "max:32",
                    expression: "!form.id ? 'required|max:32' : 'max:32'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("new-password") },
                attrs: {
                  name: "new-password",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_password"
                  ),
                  placeholder: _vm.form.id ? "******" : "",
                  type: "password",
                  id: "input-settings-form-field-password",
                },
                domProps: { value: _vm.form.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "password", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("new-password"),
                    expression: "veeErrors.has('new-password')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("new-password")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item apiAddress required" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_apiAdress"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.apiAddress,
                    expression: "form.apiAddress",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:128",
                    expression: "'required|max:128'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("apiAddress") },
                attrs: {
                  name: "apiAddress",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_apiAdress"
                  ),
                  type: "text",
                  id: "input-settings-form-field-apiAddress",
                },
                domProps: { value: _vm.form.apiAddress },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "apiAddress", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("apiAddress"),
                    expression: "veeErrors.has('apiAddress')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("apiAddress")))]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "Form-row" }, [
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item clientId required" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_clientId"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.clientId,
                    expression: "form.clientId",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:128",
                    expression: "'required|max:128'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("clientId") },
                attrs: {
                  name: "clientId",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_clientId"
                  ),
                  type: "text",
                  id: "input-settings-form-field-logo-id",
                },
                domProps: { value: _vm.form.clientId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "clientId", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("clientId"),
                    expression: "veeErrors.has('clientId')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("clientId")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item clientSecret required" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(_vm.$t("Integration.StoreGroup_FormField_clientSecret"))
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.clientSecret,
                    expression: "form.clientSecret",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: !_vm.form.id ? "required|max:128" : "max:128",
                    expression: "!form.id ? 'required|max:128' : 'max:128'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("clientSecret") },
                attrs: {
                  name: "clientSecret",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_clientSecret"
                  ),
                  placeholder: _vm.form.id ? "******" : "",
                  type: "password",
                  id: "input-settings-form-field-clientSecret",
                },
                domProps: { value: _vm.form.clientSecret },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "clientSecret", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("clientSecret"),
                    expression: "veeErrors.has('clientSecret')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("clientSecret")))]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "Form-row" }, [
        _c("div", { staticClass: "Form-col" }, [
          _c("div", { staticClass: "Form-item clientSecret" }, [
            _c("label", { staticClass: "Form-item-label" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("Integration.StoreGroup_FormField_companyDivision")
                )
              ),
            ]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.companyDivision,
                    expression: "form.companyDivision",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:32",
                    expression: "'max:32'",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("companyDivision") },
                attrs: {
                  name: "companyDivision",
                  autocomplete: "off",
                  "data-vv-as": _vm.$t(
                    "Integration.StoreGroup_FormField_companyDivision"
                  ),
                  type: "text",
                  id: "input-settings-form-field-companyDivision",
                },
                domProps: { value: _vm.form.companyDivision },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "companyDivision", $event.target.value)
                  },
                },
              }),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.veeErrors.has("companyDivision"),
                    expression: "veeErrors.has('companyDivision')",
                  },
                ],
                staticClass: "Form-item-help is-danger",
              },
              [_vm._v(_vm._s(_vm.veeErrors.first("companyDivision")))]
            ),
          ]),
        ]),
        _c("div", { staticClass: "Form-col align-self-flex-end" }, [
          _c("div", { staticClass: "Form-item" }, [_vm._t("extraField")], 2),
          _c(
            "div",
            { staticClass: "Form-item submit" },
            [
              _c(
                "Button",
                {
                  staticClass: "uppercase",
                  attrs: {
                    type: "submit",
                    primary: "",
                    size: "large",
                    variant: "full",
                    form: "integration-store-grop-setting-form",
                    id: "btn-integration-submit-store-group",
                    isLoading: _vm.isFormSubmitting || _vm.isLoading,
                    disabled:
                      _vm.isFormSubmitting ||
                      _vm.isFormDisabled ||
                      _vm.isLoading,
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Integration.User_FormField_submit"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }