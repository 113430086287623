<template lang="pug">
.Integration
  .integration-title
    h2.title {{ integrationTitle }}
    .divider

  .Box-container
    .items(v-for="(item, index) in list" :key="index")
      .item-title
        .title {{$t('Integration.' + setName(item.description))}}
        .box-header-right
          .label.m-right-15(v-if="item.isActive || item.isUsed ") {{ $t('Integration.installed')}}
          Button.m-bottom-10.action-button(
            :clearVariant="true"
            @click="openPopup(item)"
            v-click-outside="() => closePopup(item)"
          )
            span ...
              .action-popup(:class="item.showActionBar ? 'openActions' : 'closeActions'")
                Button(
                  variant="Boxlist-action Boxlist-action-edit"
                  :id="`btn-integration-overview-${item.name}`"
                  iconName="icon-login-eye",
                  iconClass="icon-login-eye",
                  :clearVariant="true"
                  @click="activateOverview(item)"
                )
                  span.m-left-10 {{ $t('Integration.overview')}}

                Button(
                  v-if="(item.to && item.isActive && item.name !== 'TCMB') || item.name === 'Sales Forecasting'"
                  variant="Boxlist-action Boxlist-action-edit"
                  :id="`btn-integration-settings-${item.name}`"
                  iconName="icon-global-settings",
                  iconClass="icon-global-settings",
                  :clearVariant="true"
                  @click="goToPage(item)"
                )
                  span.m-left-10 {{ $t('Integration.configuration')}}

                Button(
                  v-if="item.isUsed || ((item.name === 'Massec' || item.name === 'iBar' || item.name === 'TCMB' || item.name === 'EMKO') && item.isActive)"
                  variant="Boxlist-action Boxlist-action-edit"
                  :id="`btn-integration-uninstall-${item.name}`"
                  iconName="icon-delete",
                  iconClass="icon-delete",
                  :clearVariant="true"
                  @click="activateProduct(item)"
                )
                  span.m-left-10 {{ $t('Integration.uninstall')}}

                Button(
                  v-if="!item.isActive && !item.isUsed"
                  variant="Boxlist-action Boxlist-action-edit"
                  :id="`btn-integration-uninstall-${item.name}`"
                  iconName="icon-global-setup",
                  iconClass="icon-global-setup",
                  :clearVariant="true"
                  @click="activateProduct(item)"
                )
                  span.m-left-10 {{ $t('Integration.install')}}
      .box-content
        img.item_img(:src="`/static/images/integrations_${item.img}`")
        span.m-left-10.m-right-10 {{ getShortDesc( $t('Integration.description_' + setName(item.description))) }}
      Modal(:isOpen="item.isModalActive" @closeModal="closeOverview(item)")
        template(slot="title")
          | {{ $t('Integration.' + setName(item.description))}}
        template(slot="content")
          div
            span {{$t('Integration.description_' + setName(item.description))}}
</template>

<script>
export default {
  name: 'IntegrationItems',
  data () {
    return {
      showActionBar: false,
      isOverviewActive: false
    }
  },

  props: {
    integrationTitle: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    isNutrition: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openPopup (item) {
      this.$emit('openActionPopup', item)
    },
    setName (item) {
      item = item.replace(/ +/g, '')
      item = item.replace('-', '')
      item = item.replace('/', '')
      item = item.split('.').join('')
      item = item.replace(' ', '')
      return item
    },
    closePopup (item) {
      this.$emit('closeActionPopup', item)
    },
    goToPage (item) {
      item.to = item.to.replace(' ', '')
      this.$router.push({
        name: item.to,
        params: {
          IntegrationProductId: item.id
        }
      })
    },
    activateOverview (item) {
      this.$emit('activateModal', item)
    },
    closeOverview (item) {
      this.$emit('closeModal', item)
    },
    getShortDesc (text) {
      return text.length > 120 ? text.substring(0, 120).concat('...') : text
    },
    activateProduct (item) {
      if (item.name === 'iBar' && !item.isActive) this.goToPage(item)
      else if (item.name === 'Sales Forecasting' && !item.isActive) this.goToPage(item)
      else if (item.name === 'iBar' && item.isActive) this.$emit('deActivateIBar', item)
      else {
        if (item.name === 'Massec' || item.name === 'Protel' || item.name === 'TCMB' || item.name === 'EMKO') this.$emit('activateProduct', item)
        else this.$emit('activateNutrition', item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "~@/stylesheet/config/mixin";

  .divider{
    width: 90%;
    padding-left: 20px;
    height: 0.5px;
    opacity: 0.2;
    background-color: #6b6b6b;
    margin-left: 16px;
    &:nth-child(5){
      background: none !important;
      width: 0;
    }
  }
  .integration-title {
    display: flex;
    align-items: center;
  }

  .Box-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .title {
    font-size: 15px;
    font-weight: bold;
    color: #6b6b6b;
  }
  .item_img {
    width: 80px;
    height: 80px;
    margin-left: 15px;
  }
  .items {
    width: 370px;
    height: 160px;
    margin: 15px 15px;
    border-radius: 3px;
    border: solid 1px #d6dddf;
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .item-title {
    width: 100%;
    height: 44px;
    margin: 0 0 17px;
    padding: 10px 20px 9px;
    border-radius: 3px;
    border-bottom: solid 1px #d6dddf;
    justify-content: space-between;
    align-items: center;
    display: flex;

    Button {
      font-size: 24px;
    }
  }
  .action-button {
    position: relative;
  }
  .action-popup {
    @include MiniPopupMixin(
      $max-width: 175px,
      $margin: 20px 0 0 0,
      $triangle-direction: 'right',
      $padding-hor: 0,
      $padding-ver: 0,
      $display: flex,
      $border-color: #d6dddf,
    );
    width: 175px;
    height: auto;
    position: absolute;
    background: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: none;
    border: solid 1px #d6dddf;
    Button {
      font-size: 14px;
      font-weight: $font-weight-normal;
      width: 100%;
      display: flex;
      padding: 10px 10px;
      align-items: center;
      &:nth-child(2) {
      border-bottom: 1px solid #d6dddf;
      border-top: 1px solid #d6dddf;
      }
    }
  }
  .box-content {
    display: flex;
    span {
      word-break: break-word;
    }
  }
  .box-header-right {
    display: flex;
    align-items: center;

   .label {
      width: 75px;
      height: 24px;
      text-align: center;
      padding: 6px 10px;
      border-radius: 4px;
      background-color: #00c48d46;
      font-size: 11px;
      color: #00c48c;
   }
  }
  :deep() .icon-login-eye{
    width: 18px !important;
  }
  :deep() .icon-global-settings {
    width: 18px !important;
  }
  :deep() .icon-delete {
    width: 18px !important;
  }
  :deep() .icon-global-setup {
    margin-left: 2px !important;
    width: 16px;
  }
  .openActions {
    display: block;
  }
  .closeActions {
    display: none;
  }
</style>
