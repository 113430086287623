<template lang="pug">
form.Form(@submit.prevent="onSubmitStoreGroupIntegration"
  id="integration-store-grop-setting-form")
    .Form-row
      .Form-col
        .Form-item.firstname.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_companyNumber') }}
          .control
            input.txt(
            name="companyNumber"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_companyNumber')"
            v-model="form.companyNumber"
            v-validate="'required|max:32'"
            :class="{ 'is-danger': veeErrors.has('companyNumber') }"
            type="text"
            id="input-integration-form-field-company-number")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('companyNumber')")
            | {{ veeErrors.first('companyNumber') }}

      .Form-col
        .Form-item.username.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_userName') }}
          .control
            input.txt(
            name="new-username"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_userName')"
            v-model="form.username"
            v-validate="'required|max:32'"
            :class="{ 'is-danger': veeErrors.has('new-username') }"
            type="text"
            id="input-settings-form-field-username")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('new-username')")
            | {{ veeErrors.first('new-username') }}

    .Form-row

      .Form-col
        .Form-item.password.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_password') }}
          .control
            input.txt(
            name="new-password"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_password')"
            v-model="form.password"
            :placeholder="form.id ? '******' : ''"
            v-validate="!form.id ? 'required|max:32' : 'max:32'"
            :class="{ 'is-danger': veeErrors.has('new-password') }"
            type="password"
            id="input-settings-form-field-password")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('new-password')")
            | {{ veeErrors.first('new-password') }}

      .Form-col
        .Form-item.apiAddress.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_apiAdress') }}
          .control
            input.txt(
            name="apiAddress"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_apiAdress')"
            v-model="form.apiAddress"
            v-validate="'required|max:128'"
            :class="{ 'is-danger': veeErrors.has('apiAddress') }"
            type="text"
            id="input-settings-form-field-apiAddress")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('apiAddress')")
            | {{ veeErrors.first('apiAddress') }}

    .Form-row

      .Form-col
        .Form-item.clientId.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_clientId') }}
          .control
            input.txt(
            name="clientId"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_clientId')"
            v-model="form.clientId"
            v-validate="'required|max:128'"
            :class="{ 'is-danger': veeErrors.has('clientId') }"
            type="text"
            id="input-settings-form-field-logo-id")

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('clientId')")
            | {{ veeErrors.first('clientId') }}

      .Form-col
        .Form-item.clientSecret.required
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_clientSecret') }}
          .control
            input.txt(
            name="clientSecret"
            autocomplete="off"
            :data-vv-as="$t('Integration.StoreGroup_FormField_clientSecret')"
            v-model="form.clientSecret"
            :placeholder="form.id ? '******' : ''"
            v-validate="!form.id ? 'required|max:128' : 'max:128'"
            :class="{ 'is-danger': veeErrors.has('clientSecret') }"
            type="password"
            id="input-settings-form-field-clientSecret")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('clientSecret')")
            | {{ veeErrors.first('clientSecret') }}

    .Form-row
      .Form-col
        .Form-item.clientSecret
          label.Form-item-label {{ $t('Integration.StoreGroup_FormField_companyDivision') }}
          .control
            input.txt(
            name="companyDivision",
            autocomplete="off",
            :data-vv-as="$t('Integration.StoreGroup_FormField_companyDivision')",
            v-model="form.companyDivision",
            :class="{ 'is-danger': veeErrors.has('companyDivision') }",
            type="text",
            v-validate="'max:32'",
            id="input-settings-form-field-companyDivision")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('companyDivision')")
            | {{ veeErrors.first('companyDivision') }}
      .Form-col.align-self-flex-end
        .Form-item
          slot(name="extraField")
        .Form-item.submit
          Button.uppercase(
            type="submit",
            primary
            size="large"
            variant="full"
            form="integration-store-grop-setting-form"
            id="btn-integration-submit-store-group"
            :isLoading="isFormSubmitting || isLoading",
            :disabled="isFormSubmitting || isFormDisabled || isLoading"
          )
            span {{ $t('Integration.User_FormField_submit') }}

</template>

<script>

export default {
  name: 'IntegrationSettingsForm',

  props: {
    form: {
      type: Object,
      default: null
    },
    onSubmitAction: {
      type: Function,
      default: null
    },
    isLoadingAction: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isFormSubmitting () {
      return this.$wait.is('formSubmitting')
    },

    isLoading () {
      return this.isLoadingAction
    },

    isFormDisabled () {
      let formNotNullFields = Object.keys(this.form).filter(key => (key !== 'storeGroupName' && key !== 'integrationProductId' && !!this.form[key]))
      return formNotNullFields.length === 0
    }
  },

  methods: {
    async onSubmitStoreGroupIntegration () {
      await this.$wait.start('formSubmitting')
      const isValid = await this.$validator.validateAll()
      if (!isValid) {
        await this.$wait.end('formSubmitting')
        return
      }
      await this.submit()
      await this.$wait.end('formSubmitting')
    },

    async submit () {
      await this.onSubmitAction(this.form)
    }
  }
}
</script>

<style lang="scss" scoped>

  .Form {
    border: 1px solid $color-gray;
    padding: 25px;
    border-radius: $border-radius;

    .Form-row {
      display: flex;

        .Form-col {
          width: 50%;
        }

        .Form-col:first-child {
          margin-right: 20px;
        }
      }

      .Form-row:not(:first-child) {

        .Form-col {
          margin-top: 20px;
      }
    }
  }
</style>
