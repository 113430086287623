<template lang="pug">
div
  h1.success-message {{ $t('Integration.xeroConnectionSuccess') }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'XeroAuthorization',

  data() {
    return {
      timer: 2,
      timeout: null
    }
  },

  computed: {
    ...mapGetters('Settings', ['permittedStoreGroupList']),
    ...mapGetters('Integration', ['xeroIntegrationProductId'])
  },

  methods: {
    ...mapActions('IntegrationAccountingLogo', [
      'saveIntegrationStoreGroup',
      'getIntegrationStoreGroup',
      'fetchIntStoreGroupList'
    ]),

    ...mapActions('Integration', ['getCompanyNameForXero']),

    ...mapActions('Notify', ['notifyShow']),

    countdown() {
      const searchParams = new URLSearchParams(window.location.search);
      const state = searchParams.get('state');
      const code = searchParams.get('code');

      if (this.timer === 0) {
        this.$router.push({
          name: 'IntegrationAccountingXero',
          params: {
            IntegrationProductId: state,
            code,
            checkCompanyName: true
          }
        });
      }
      if (this.timer > 0) {
        this.timeout = setTimeout(() => {
          this.timer--;
          this.countdown();
        }, 1000);
      }
    }
  },

  mounted() {
    this.countdown();
  }
}
</script>

<style lang="scss" scoped>
.success-message {
  color: $color-sidebar-dark;
}
</style>
