import converCaptalize from '@/utils/convert-capitalize'
export function getConfigFields($t, type) {
  const name = `${type}Name`
  return [
    {
      name,
      text: $t(`Dictionary.${converCaptalize(type)}`)
    },
    {
      name: 'code',
      text: $t('B2B.IntegrationCode'),
      isEditable: true,
      input: {
        validateRule: 'max:32'
      }
    }
  ]
}
