<template lang="pug">
Page(titleType="custom")
  template(slot="header-left-dropdown")
    div
  template(slot="content-center")
    .settings
      .settings-item
        .settings-header
          label.settings-label {{ $t('Integration.Settings_Simpra_Pos') }}
        .settings-content
          TableLoading(v-if="isFetchingPageData")
          IntegrationTable(
            v-else
            :isFocusable="false",
            @onChange="updateItem",
            :fields="integrationFields",
            :data="configList || []"
            :isLoadingAction="isLoading"
          )
      .settings-item
        .settings-header
          label.settings-label {{ $t('Integration.Settings_Simpra_Pos_Sync') }}
        Loading(v-if="$wait.is('getPosMenuItemSyncWithStockItemStatus')")
        .settings-content.sync(v-else)
          CustomCheckbox(
            id="checkbox-menu-items-sync"
            :label="$t('Integration.Checkbox_Menu_Item_Sync')"
            v-model="menuItemSyncWithStockItemStatus",
          )
          Button(
            id="button-submit-menu-items-sync"
            primary
            size="small"
            :disabled="isLoading"
            :isLoading="isLoading"
            @click="sendPosMenuItemSyncWithStockItemStatus"
          ) {{ $t('Integration.Submit_Button_Menu_Item_Sync') }}
</template>

<script>
import IntegrationTable from '@/view/pages/Integration/components/Table'

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'SimpraPosIntegration',
  /*
  */
  components: {
    IntegrationTable,
  },
  /*
  */
  data () {
    return {
      IntegrationProductId: this.$route.params.IntegrationProductId,
      menuItemSyncWithStockItemStatus: false,
      integrationFields: [
        {
          name: 'storeGroupName',
          text: this.$t('Dictionary.StoreGroup')
        },
        { name: 'propertyKey',
          isEditable: true,
          text: 'Property Key',
          input: {
            validateRule: `min:2|very_singularity:integration/pos/config/check/propertyKey?storeGroupId=,propertyKey`,
            validateSingularityParams: 'storeGroupId'
          }
        }
      ]
    }
  },

  /*
  */

  computed: {

    isFetchingPageData () {
      return this.$wait.is('fetchSimpraPosStoreGroupList')
    },

    isLoading () {
      return this.$wait.is(['saveIntegrationSimprapropertyKeyItem', 'sendPosMenuItemSyncWithStockItemStatusItem'])
    },

    ...mapGetters('IntegrationSimpraPos', ['configList'])

  },

  /*
  */

  methods: {

    ...mapActions('IntegrationSimpraPos', [
      'fetchSimpraPosStoreGroupList',
      'saveIntegrationSimprapropertyKey',
      'getPosMenuItemSyncWithStockItemStatus',
      'setPosMenuItemSyncWithStockItemStatus'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('IntegrationSimpraPos', ['RESET_INT_STORE_LIST']),

    sendPosMenuItemSyncWithStockItemStatus: vueWaitLoader(async function () {
      await this.setPosMenuItemSyncWithStockItemStatus({menuItemSyncWithStockItemStatus: this.menuItemSyncWithStockItemStatus})
        .then(res => {
          if (!res) return
          this.notifyShow({ message: this.$t('Integration.Settings_menuItemSyncWithStockItemStatus_Saved_Successful') })
        })
    }, 'sendPosMenuItemSyncWithStockItemStatusItem'),

    updateItem: vueWaitLoader(async function (row) {
      const payload = {
        integrationProductId: this.IntegrationProductId,
        configList: [
          {
            storeGroupId: row.storeGroupId,
            propertyKey: row.propertyKey
          }
        ]
      }
      const res = await this.saveIntegrationSimprapropertyKey(payload)
      if (res) {
        const message = this.$t('Integration.TaxSuccessUpdated', { name: row.storeGroupName })
        this.notifyShow({ message })
      }
    }, 'saveIntegrationSimprapropertyKeyItem')
  },

  async beforeMount () {
    this.getPosMenuItemSyncWithStockItemStatus()
      .then(res => {
        this.menuItemSyncWithStockItemStatus = res.data.menuItemSyncWithStockItemStatus
      })
    await this.fetchSimpraPosStoreGroupList({
      IntegrationProductId: this.IntegrationProductId
    })
  },

  beforeDestroy () {
    this.RESET_INT_STORE_LIST()
  }
}
</script>

<style lang="scss" scoped>

.settings {

  &-header {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    font-size: $font-size-big;
    color: $color-dark;
    opacity: 0.46;
  }

  &-content.sync {
    display: flex;

    button {
      margin-left: 15px;
      width: max-content;
    }
  }

  &-item:not(:first-child) {
    margin-top: 30px;
  }

  &-label {

    &:after {
      content: "";
      position: absolute;
      display: inline-block;
      color: $color-dark;
      opacity: 0.46;
      width: 100%;
      height: 1px;
      background-color: black;
      margin-left: 10px;
      top: 50%;
    }
  }
}
</style>
