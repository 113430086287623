<template lang="pug">
Page(titleType="custom")
    template(slot="header-right-content")
      Button(
        v-show="!isEmpytList",
        primary,
        size="medium",
        @click="gotoNewIntegration",
        id="integration-new-b2b-b2b"
      ) {{ $t('B2B.addNewB2bIntegration') }}

    template(slot="content-center")
      TableLoading(v-if="isFetchingList")
      template(v-else)
        EmptyState(
          v-if="isEmpytList",
          :title="$t('Global.emptyState_Title', { field: $t('Dictionary.Integration_B2B') })",
          :description="$t('Global.emptyState_Description', { field: $t('Dictionary.Integration_B2B') })",
          :buttonText="$t('B2B.addNewB2bIntegration')",
          @emptyAddButton="gotoNewIntegration")

        BoxView(
          v-else
          :fields="b2bGetFields"
          :actions="['edit', 'remove']"
          :data="B2bDefinitionList"
          :extraClass="{forContent: 'box-flex-row m-auto'}"
          :componentName="'B2BIntegrationBox'"
          @delegateOnItemShow="onItemClick"
          @delegateOnItemEdit="onActionClick('edit', $event)"
          @delegateOnItemRemove="onActionClick('delete', $event)"
        )
      router-view
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'IntegrationB2B',

  computed: {
    ...mapGetters('IntegrationB2b', ['B2bDefinitionList']),

    isFetchingList () {
      return this.$wait.is('fetchingB2bDefinitionList')
    },

    isEmpytList () {
      return this.B2bDefinitionList.length === 0
    },

    b2bGetFields () {
      return [
        {
          name: 'name',
          is_visible: true,
          headerTitle: true
        },
        {
          name: 'supplierNames',
          is_visible: true,
          short_title: this.$t('Dictionary.Supplier'),
          title: this.$t('Dictionary.Supplier')
        }
      ]
    }

  },

  /**
     **/

  methods: {
    ...mapActions('IntegrationB2b', [
      'fetchingB2bDefinitionList'
    ]),

    onItemClick (event) {
      this.$router.push({
        name: 'IntegrationB2bB2BDataConfig',
        params: {
          IntegrationProductId: this.$route.params.IntegrationProductId,
          id: event.id
        }
      })
    },

    onActionClick (to, event) {
      this.$router.push({
        name: to + 'B2bConfig',
        params: {
          IntegrationProductId: this.$route.params.IntegrationProductId,
          id: event.id
        }
      })
    },
    gotoNewIntegration () {
      this.$router.push({
        name: 'newB2bConfig',
        params: {
          IntegrationProductId: this.$route.params.IntegrationProductId
        }
      })
    }
  },
  
  mounted () {
    this.fetchingB2bDefinitionList({
      IntegrationProductId: this.$route.params.IntegrationProductId
    })
  }
}
</script>

<style lang="scss" scoped>

  :deep() .boxLink {
    cursor: pointer !important
  }
</style>
