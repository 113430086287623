var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Page",
    { attrs: { titleType: "custom" } },
    [
      _c("template", { slot: "header-left-dropdown" }, [_c("div")]),
      _c("template", { slot: "content-center" }, [
        _c("div", { staticClass: "settings" }, [
          _c("div", { staticClass: "settings-item" }, [
            _c("div", { staticClass: "settings-header" }, [
              _c("label", { staticClass: "settings-label" }, [
                _vm._v(_vm._s(_vm.$t("Integration.Settings_Emko"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "settings-content" },
              [
                _vm.isFetchingPageData
                  ? _c("TableLoading")
                  : _c("IntegrationTable", {
                      attrs: {
                        isFocusable: false,
                        fields: _vm.integrationFields,
                        data: _vm.configList,
                        isLoadingAction: _vm.isLoading,
                      },
                      on: { onChange: _vm.updateItem },
                    }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }