<template lang="pug">
Page
  template(slot="header-left-dropdown")
    PageHeaderTabs.m-left-10(
      :links="PageHeaderLinks",
      :disabledValues="filteredSettingsMenu",
      translationGroup="Accounting"
    )
  template(slot="overgroups-left")
    ToggleOverGroups.m-bottom-15(
      :list="IntStoreGroupList",
      :selectedItem="selectedStoreGroup",
      @delegateChangeSelectedItem="onChangeStoreGroup",
      selectedKey="storeGroupId",
      fieldKey="storeGroupName"
    )
  template(slot="content-center")
    TableLoading(v-if="isFetchingSettingInfo")
    SettingsForm(
      v-else,
      :form="form",
      :isLoadingAction="isLoading"
      :onSubmitAction="onSubmitForm"
    )
</template>

<script>

//
import ToggleOverGroups from '@/view/global/toggle-over-groups'
import SettingsForm from '@/view/pages/Integration/components/SettingsForm'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Integration',

  components: {
    ToggleOverGroups,
    SettingsForm
  },

  /*
*/

  data () {
    return {
      selectedStoreGroup: {},
      form: {
        companyNumber: null,
        username: null,
        password: null,
        apiAddress: null,
        clientId: null,
        clientSecret: null,
        companyDivision: null
      }
    }
  },

  /*
*/

  computed: {
    ...mapGetters('Settings', [
      'permittedStoreGroupList'
    ]),
    ...mapGetters('IntegrationAccountingLogo', [
      'PageHeaderLinks',
      'IntStoreGroupList'
    ]),
    isFetchingSettingInfo () {
      return this.$wait.is(['getIntegrationStoreGroup', 'pageLoading'])
    },
    filteredSettingsMenu () {
      return this.form.id ? [] : this.PageHeaderLinks.filter((d, index) => index !== 0)
    },

    IntegrationProductId () {
      return this.$route.params.IntegrationProductId
    },

    isLoading () {
      return this.$wait.is(['getListAccountintgLogo'])
    }
  },

  /*
*/

  methods: {
    ...mapActions('IntegrationAccountingLogo', [
      'saveIntegrationStoreGroup',
      'getIntegrationStoreGroup',
      'fetchIntStoreGroupList'
    ]),

    ...mapActions('Settings', [
      'setDefaultSettings'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    onChangeStoreGroup (storeGroup) {
      if (this.selectedStoreGroup === storeGroup) return
      this.selectedStoreGroup = storeGroup
      const params = {
        settings: {
          SelectedStoreGroupIdOnLogo: this.selectedStoreGroup.storeGroupId
        }
      }
      this.setDefaultSettings(params)
      this.getIntegrationStoreGroupWithParams()
    },

    setDefaultStoreGroup () {
      let selectedStoreGroup = this.IntStoreGroupList.find(storeGroup => storeGroup.isLastSelected)
      if (!selectedStoreGroup) selectedStoreGroup = this.IntStoreGroupList.find(storeGroup => storeGroup.isStoreGroupDefault)
      this.onChangeStoreGroup(selectedStoreGroup)
    },

    getIntegrationStoreGroupWithParams () {
      const params = {
        StoreGroupId: this.selectedStoreGroup.storeGroupId,
        IntegrationProductId: this.IntegrationProductId
      }
      this.getIntegrationStoreGroup(params)
        .then(res => {
          this.form = res.data.intStoreGroup
          this.$validator.reset()
        })
    },

    onSubmitForm: vueWaitLoader(async function (form) {
      let params = {
        ...form,
        storeGroupId: this.selectedStoreGroup.storeGroupId,
        integrationproductId: this.IntegrationProductId
      }
      const res = await this.saveIntegrationStoreGroup(params)
      if (!res) return
      const message = this.$t('Integration.StoreGroupSuccessUpdated')
      this.notifyShow({ message })
      this.getIntegrationStoreGroupWithParams()
    }, 'getListAccountintgLogo')
  },

  /*
*/
  async beforeMount () {
    this.$wait.start('pageLoading')
    await this.fetchIntStoreGroupList({
      IntegrationProductId: this.IntegrationProductId
    })
    this.setDefaultStoreGroup()
    this.$wait.end('pageLoading')
  }
}
</script>
